import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { app_url } from "../../config";
import { useNavigate } from "react-router-dom";

const AddPortalUser = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  // Handle State Change
  const handleStateChange = (event) => {
    const value = event.target.value;

    const isValidStateFormat = /^[A-Za-z]+(?:[ ][A-Za-z]+)*$/.test(value);

    if (!isValidStateFormat && value !== "") {
      setStateError("State can contain alphabets only.");
    } else {
      setStateError("");
    }

    setState(value);
  };

  // Handle Country Change
  const handleCountryChange = (event) => {
    const value = event.target.value;

    const isValidCountryFormat = /^[A-Za-z]+(?:[ ][A-Za-z]+)*$/.test(value);

    if (!isValidCountryFormat && value !== "") {
      setCountryError("Country can contain alphabets only.");
    } else {
      setCountryError("");
    }

    setCountry(value);
  };

  const handleCityChange = (event) => {
    const value = event.target.value;

    // Regex for alphanumeric, case-sensitive, with allowed special characters and spaces
    const isValidCityFormat = /^[A-Za-z]+( [A-Za-z]+)*$/.test(value);

    if (!isValidCityFormat && value !== "") {
      setCityError("City can contain alphabets only");
    } else {
      setCityError("");
    }

    setCity(value);
  };

  const handleFirstNameChange = (event) => {
    const value = event.target.value;

    // Regex for alphanumeric, case-sensitive, with allowed special characters and spaces
    const isValidFirstNameFormat = /^[A-Za-z]+(?:[-'][A-Za-z]+)?$/.test(value);

    if (!isValidFirstNameFormat && value !== "") {
      setFirstNameError("First Name can contain alphabets only");
    } else {
      setFirstNameError("");
    }

    setFirstName(value);
  };

  const handleLastNameChange = (event) => {
    const value = event.target.value;

    // Regex for alphanumeric, case-sensitive, with allowed special characters and spaces
    const isValidLastNameFormat = /^[A-Za-z]+(?:[-'][A-Za-z]+)?$/.test(value);

    if (!isValidLastNameFormat && value !== "") {
      setLastNameError("Last Name can contain alphabets only");
    } else {
      setLastNameError("");
    }

    setLastName(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    const isValidPasswordFormat = /^[a-zA-Z0-9@_\[\].,]+$/.test(value);

    if (!isValidPasswordFormat && value !== "") {
      setPasswordError(
        "Password must be alphanumeric, case-sensitive, no spaces, and may contain @, _, [, ] or ,"
      );
    } else {
      setPasswordError("");
    }

    setPassword(value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;

    // Check if the value matches the email format
    const isValidEmailFormat =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(value);

    // Reset error messages
    setEmailError("");

    if (!isValidEmailFormat) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    setEmail(value);
  };

  const handleAddressChange = (event) => {
    const value = event.target.value;

    // Regex to validate alphanumeric, case-sensitive, spaces, and specific special characters
    const isValidAddressFormat =
      /^(?! )[a-zA-Z0-9@.&()'\/\- ]{5,255}(?<! )$/.test(value);
    // console.log(isValidAddressFormat, "valid check");

    if (!isValidAddressFormat && value !== "") {
      setAddressError(
        "Address can contain alphanumeric characters, spaces, and @, ., &, (, ), #, -."
      );
    } else {
      setAddressError("");
    }

    setAddress(value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    if (
      firstNameError ||
      lastNameError ||
      passwordError ||
      emailError ||
      cityError ||
      stateError ||
      countryError ||
      addressError
    ) {
      toast.error("Please fix the errors before submitting the form.");
      return;
    }
    const data = {
      first_name: firstName,
      password: password,
      email: email,
      last_name: lastName,
      userType: selectedUserType,
      city: city,
      state: state,
      country: country,
      address: address,
      mobile: phoneNumber,
    };
    axios
      .post(`${app_url}/api/portalUser/registerPortalUser`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            Add Portal User
          </p>
        </div>
      </div>
      <form className="" onSubmit={handleSubmit}>
        {/* Personal Information */}
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Personal Information</p>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="firstName" className="form-label">
                First Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="Enter First Name"
                required
                maxLength={30}
                onChange={handleFirstNameChange}
              />
              {firstNameError && (
                <small className="text-danger mt-1">{firstNameError}</small>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="lastName" className="form-label">
                Last Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Enter Last Name"
                required
                maxLength={30}
                onChange={handleLastNameChange}
              />
              {lastNameError && (
                <small className="text-danger mt-1">{lastNameError}</small>
              )}
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email"
                required
                maxLength={30}
                onChange={handleEmailChange}
              />
              {emailError && (
                <div className="text-danger mt-1">{emailError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <PhoneInput
                value={phoneNumber}
                id="phoneNumber"
                country={"us"}
                onChange={(phone) => setPhoneNumber(phone)}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="userType" className="form-label">
                Select User Type*
              </label>
              <select
                value={selectedUserType}
                className="form-control"
                id="userType"
                required
                onChange={(e) => setSelectedUserType(e.target.value)}
              >
                <option value="" disabled>
                  Select User Type
                </option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Admin</option>
                <option value="support">Support</option>
                <option value="marketing">Marketing</option>
                <option value="billing">Billing</option>
                <option value="sales">Sales</option>
              </select>
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="password" className="form-label">
                Password*
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={handlePasswordChange}
                placeholder="Enter Password"
                required
                maxLength={100}
              />
              {passwordError && (
                <div className="text-danger mt-1">{passwordError}</div>
              )}
            </div>
          </div>
        </div>
        {/* Address */}
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Address</p>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="city" className="form-label">
                City*
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="Enter city"
                required
                maxLength={30}
                onChange={handleCityChange}
              />
              {cityError && <div className="text-danger mt-1">{cityError}</div>}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="state" className="form-label">
                State*
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder="Enter state"
                value={state}
                onChange={handleStateChange}
                required
              />
              {stateError && (
                <div className="text-danger mt-1">{stateError}</div>
              )}
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="country" className="form-label">
                Country*
              </label>
              <input
                type="text"
                className="form-control"
                id="country"
                placeholder="Enter country"
                value={country}
                onChange={handleCountryChange}
                required
              />
              {countryError && (
                <div className="text-danger mt-1">{countryError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Enter Address"
                maxLength={255}
                onChange={handleAddressChange}
              />
              {addressError && (
                <small className="text-danger mt-1">{addressError}</small>
              )}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <button type=" submit" className="btn primary-btn mt-4 px-4">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default AddPortalUser;
