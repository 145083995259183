import React, { useEffect } from "react";
import { FaCalendar } from "react-icons/fa";
import { LuArrowDownUp } from "react-icons/lu";
import { LiaSignalSolid } from "react-icons/lia";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Loader from "../Loader";
import active from "../../../src/assets/images/power-on.png";
import expire from "../../../src/assets/images/time.png";
import finish from "../../../src/assets/images/product.png";
import packageIcon from "../../../src/assets/images/box.png";

const UsageModal = ({ show, handleClose, eSimDetail, isLoading }) => {
  // console.log(eSimDetail, "package check");

  const formatDate = (date) =>
    date && date !== "0000-00-00 00:00:00"
      ? new Date(date).toLocaleString()
      : "N/A";

  const renderPackageHistory = (item) => {
    switch (item?.status_v2) {
      case "NOT_ACTIVE":
        return (
          <>
            <div className="fw-semibold d-flex my-2 align-items-center gap-2">
              <div
                style={{ width: "34%" }}
                className="d-flex align-items-center"
              >
                <MdOutlineAssignmentTurnedIn className="me-2" size={20} />
                <p className="mb-0">ISSUED</p>
              </div>
              <span
                className="fw-normal"
                style={{ marginLeft: "0em", width: "62%" }}
              >
                {formatDate(item?.createdAt)}
              </span>
            </div>
          </>
        );
      case "ACTIVE":
      case "EXPIRED":
      case "REFUNDED":
        return (
          <>
            <div className="fw-semibold d-flex my-2 align-items-center gap-2">
              <div
                style={{ width: "34%" }}
                className="d-flex align-items-center"
              >
                <img
                  src={active}
                  width={16}
                  height={16}
                  alt="activation icon"
                  className="me-2"
                />
                <p className="mb-0">ACTIVATION</p>
              </div>
              <span
                className="fw-normal"
                style={{ marginLeft: "0em", width: "62%" }}
              >
                {formatDate(item?.activated_at)}
              </span>
            </div>
            <div className="fw-semibold d-flex my-2 align-items-center gap-2">
              <div
                style={{ width: "34%" }}
                className="d-flex align-items-center"
              >
                <img
                  src={expire}
                  width={18}
                  height={18}
                  alt="expire icon"
                  className="me-2"
                />
                <p className="mb-0">
                  {item?.status_v2 === "REFUNDED" ? "REFUND" : "EXPIRY"}
                </p>
              </div>
              <span
                className="fw-normal"
                style={{ marginLeft: "0em", width: "62%" }}
              >
                {formatDate(item?.expired_at)}
              </span>
            </div>
          </>
        );
      case "FINISHED":
        return (
          <>
            <div className="fw-semibold d-flex my-2 align-items-center gap-2">
              <div
                style={{ width: "34%" }}
                className="d-flex align-items-center"
              >
                <img
                  src={active}
                  width={16}
                  height={16}
                  alt="activation icon"
                  className="me-2"
                />
                <p className="mb-0">ACTIVATION</p>
              </div>
              <span
                className="fw-normal"
                style={{ marginLeft: "0em", width: "62%" }}
              >
                {formatDate(item?.activated_at)}
              </span>
            </div>
            <div className="fw-semibold d-flex my-2 align-items-center gap-2">
              <div
                style={{ width: "34%" }}
                className="d-flex align-items-center"
              >
                <img
                  src={finish}
                  width={24}
                  height={24}
                  alt="activation icon"
                  className="me-2"
                />
                <p className="mb-0">FINISH</p>
              </div>
              <span
                className="fw-normal"
                style={{ marginLeft: "0em", width: "62%" }}
              >
                {formatDate(item?.finished_at)}
              </span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  // Function to get the badge color with opacity based on the status
  const getStatusColor = (status) => {
    switch (status) {
      case "NOT_ACTIVE":
        return "rgba(233, 233, 4, 0.7)"; // Yellow with opacity
      case "ACTIVE":
        return "rgba(32, 175, 36, 0.7)"; // Green with opacity
      case "FINISHED":
        return "rgba(231, 0, 0, 0.7)"; // Red with opacity
      case "EXPIRED":
        return "rgba(231, 0, 0, 0.7)"; // Gray with opacity
      case "REFUNDED":
        return "rgba(77, 116, 153, 0.7)"; // Blue with opacity
      default:
        return "rgba(108, 117, 125, 0.7)"; // Default color with opacity
    }
  };

  const renderNetworkDetails = () => {
    if (eSimDetail?.eSim?.tr_lastSeen === "0000-00-00 00:00:00") {
      return null;
    }

    return (
      <div className="card shadow-sm bg-light mb-3 rounded">
        <div className="card-body">
          <h5 className="card-title mb-0">NETWORK DETAILS</h5>
          <hr />
          <div className="mt-4">
            <p className="mb-1">
              <strong className="fw-semibold">MOBILE NETWORK CODE:</strong>{" "}
              {eSimDetail?.eSim?.tr_mobileNetworkCode || "N/A"}
            </p>
            <p className="mb-1">
              <strong className="fw-semibold">NETWORK NAME:</strong>{" "}
              {eSimDetail?.eSim?.tr_networkName || "N/A"}
            </p>
            <p className="mb-1">
              <strong className="fw-semibold">NETWORK BRAND NAME:</strong>{" "}
              {eSimDetail?.eSim?.tr_networkBrandName || "N/A"}
            </p>
            <p className="mb-1">
              <strong className="fw-semibold">COUNTRY:</strong>{" "}
              {eSimDetail?.eSim?.tr_country || "N/A"}
            </p>
            <p className="mb-0">
              <strong className="fw-semibold">LAST SEEN:</strong>{" "}
              {formatDate(eSimDetail?.eSim?.tr_lastSeen)}
            </p>
            <p className="mb-0">
              <strong className="fw-semibold">IMEI:</strong>{" "}
              {eSimDetail?.eSim?.tr_imei === null
                ? "N/A"
                : eSimDetail?.esim?.tr_imei}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      style={{ backgroundColor: show ? "rgba(0, 0, 0, 0.5)" : "transparent" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Usage</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <div
                style={{
                  height: "20vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "8em",
                }}
              >
                <Loader />
              </div>
            ) : (
              <>
                <div className="card shadow-sm bg-light mb-3 rounded">
                  <div className="card-body">
                    <p className="mb-1">
                      <strong className="fw-semibold">COVERAGE:</strong>{" "}
                      {eSimDetail?.eSim?.coverage}
                    </p>
                    <p className="mb-1">
                      <strong className="fw-semibold">PACKAGE:</strong>{" "}
                      {eSimDetail?.eSim?.packageName}
                    </p>
                    <p className="mb-1">
                      <strong className="fw-semibold">ICCID:</strong>{" "}
                      {eSimDetail?.eSim?.iccid}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    maxHeight: "48em",
                    overflowY: "scroll",
                    paddingRight: "3px",
                  }}
                >
                  {/* Render Network Details */}
                  {renderNetworkDetails()}
                  <div className="card shadow-sm mb-2 mt-3 bg-white rounded">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="card-title mb-0">
                          {" "}
                          <LuArrowDownUp className="me-2" size={20} /> CURRENT
                          BUNDLE DATA
                        </h5>
                        <LiaSignalSolid size={20} />
                      </div>
                      <hr />
                      <div className="d-flex gap-2 flex-wrap justify-content-around align-items-center mt-4">
                        <div
                          style={{ width: 150, height: "auto" }}
                          className="position-relative d-flex flex-column justify-content-center align-items-center"
                        >
                          <CircularProgressbar
                            value={eSimDetail?.circular_value}
                            // text={`${Math.round(percentage)}%`}
                            styles={buildStyles({
                              rotation: 0,
                              strokeLinecap: "butt",
                              textSize: "16px",
                              pathTransitionDuration: 0.5,
                              pathColor: `url(#gradient)`, // Use gradient
                              textColor: "#f88",
                              trailColor: "#d6d6d6",
                            })}
                            strokeWidth={10}
                          />
                          <svg style={{ height: 0 }}>
                            <defs>
                              <linearGradient
                                id="gradient"
                                x1="0%"
                                y1="0%"
                                x2="100%"
                                y2="0%"
                              >
                                <stop
                                  offset="0%"
                                  style={{
                                    stopColor: "#F57C21",
                                    stopOpacity: 1,
                                  }}
                                />
                                <stop
                                  offset="100%"
                                  style={{
                                    stopColor: "#F57C21",
                                    stopOpacity: 1,
                                  }}
                                />
                              </linearGradient>
                            </defs>
                          </svg>

                          <div
                            className="text-center pie-data position-absolute"
                            style={{ top: "38%", left: "17%" }}
                          >
                            <p className="fs-6 mb-0 fw-semibold">
                              {eSimDetail?.remaining_data} MB
                            </p>
                            <p
                              className="text-secondary mb-0"
                              style={{ color: "lightgray", fontSize: "12px" }}
                            >
                              REMAINING DATA
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            <p
                              className="fw-semibold mb-0"
                              style={{ color: "lightgray" }}
                            >
                              USED DATA
                            </p>
                            <p className="">{eSimDetail?.used_data} MB</p>
                          </div>
                          <div className="">
                            <p
                              className="fw-semibold mb-0"
                              style={{ color: "lightgray" }}
                            >
                              Total Data
                            </p>
                            <p className=""> {eSimDetail?.total_data} MB</p>
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex align-items-end justify-content-end">
                        <h6
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            color: "#333",
                            border: "1px solid #ccc",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: getStatusColor(
                              eSimDetail?.eSim?.status
                            ),
                          }}
                        >
                          {eSimDetail?.eSim?.status.replace(`_`, " ")}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card shadow-sm mb-2 mt-3 bg-white rounded"
                    style={{ height: "21em", overflowY: "scroll" }}
                  >
                    <div className="card-body" style={{ paddingRight: "7px" }}>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="card-title mb-0">eSIM PACKAGES</h5>
                        <img
                          src={packageIcon}
                          width={20}
                          height={20}
                          alt="activation icon"
                          className="me-2"
                        />
                      </div>
                      <hr />
                      {eSimDetail?.package_history?.map((item, index) => (
                        <>
                          <div key={index}>
                            <span
                              className={`badge
                            )} p-2 fs-6 mt-2`}
                              style={{
                                backgroundColor: getStatusColor(
                                  item?.status_v2
                                ),
                              }}
                            >
                              {item?.status_v2.replace("_", " ")}
                            </span>
                            <div className="card shadow-sm shadow-sm p-3">
                              <div className=" d-flex align-items-end justify-content-end">
                                <h6
                                  style={{
                                    margin: 0,
                                    fontWeight: "bold",
                                    color: "#333",
                                    border: "1px solid #ccc",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  {item?.packageType}
                                </h6>
                              </div>
                              {renderPackageHistory(item)}
                              <hr />
                              <div className="d-flex justify-content-between my-2">
                                <div className="fw-semibold d-flex align-items-center">
                                  <FaCalendar className="me-2" size={18} />
                                  <span>VALIDITY</span>
                                </div>
                                <div className="fw-semibold">
                                  {item?.validity} Days
                                </div>
                                <div className="fw-semibold d-flex align-items-center">
                                  <LuArrowDownUp className="me-2" size={20} />
                                  <span>DATA</span>
                                </div>
                                <div className="fw-semibold">{item?.data}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageModal;
