import React, { useEffect, useState } from "react";
import youtubeIcon from "../../../src/assets/images/youtube.png";
// import twitterIcon from "../../../src/assets/images/twitter.png";
import facebookIcon from "../../../src/assets/images/facebook.png";
import instagramIcon from "../../../src/assets/images/instagram.png";
import webIcon from "../../../src/assets/images/world-wide-web.png";
import tiktokIcon from "../../../src/assets/images/tiktok.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import toast from "react-hot-toast";
import { app_url } from "../../config";
import { useNavigate } from "react-router-dom";
import SocialMediaLinksModal from "../../components/modals/SocialMediaLinksModal";

const AddPartner = () => {
  const [username, setUsername] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [agentName, setAgentName] = useState("");
  const [agentNameError, setAgentNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [portalLink, setPortalLink] = useState("");
  const [portalLinkError, setPortalLinkError] = useState("");
  const [discountPlan, setDiscountPlan] = useState([]);
  const [selectedDiscountPlan, setSelectedDiscountPlan] = useState(null);
  const [partnerManager, setPartnerManager] = useState([]);
  const [selectedPartnerManager, setSelectedPartnerManager] = useState(null);
  const [accountMode, setAccountMode] = useState([
    { id: 1, name: "sandbox" },
    { id: 2, name: "live" },
  ]);
  const [selectedaccountMode, setSelectedAccountMode] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([
    { slug: "youtube", name: "Youtube", url: null },
    { slug: "facebook", name: "Facebook", url: null },
    { slug: "instagram", name: "Instagram", url: null },
    { slug: "tiktok", name: "Tiktok", url: null },
    { slug: "website", name: "Website", url: null },
  ]);
  const [showModal, setShowModal] = useState(false);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();

  console.log(portalLink, "portal link check");

  // Deep copy of socialMediaLinks for tempLinks to avoid direct mutation
  const [tempLinks, setTempLinks] = useState(
    JSON.parse(JSON.stringify(socialMediaLinks))
  );

  const handleModalClose = () => {
    setShowModal(false);
    setTempLinks(JSON.parse(JSON.stringify(socialMediaLinks)));
    // console.log(tempLinks, "temp links check");
  };

  const handleModalShow = () => setShowModal(true);

  const handleUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedLinks = JSON.parse(JSON.stringify(tempLinks));

    // If input is empty, set url to null
    updatedLinks[index].url = value === "" ? null : value;

    // URL Validation: Check if URL starts with http:// or https://
    const urlPattern = /^(https?:\/\/)/;

    if (value && !urlPattern.test(value)) {
      updatedLinks[index].error = "URL must start with http:// or https://";
    } else {
      updatedLinks[index].error = "";
    }
    setTempLinks(updatedLinks);
  };

  const handlePortalUrl = (event) => {
    const value = event.target.value;

    // URL Validation: Check if URL starts with http:// or https://
    const isValidUrlPattern = /^(https?:\/\/)/.test(value);

    // Update the urlPattern value and error message based on validity
    if (!isValidUrlPattern && value !== "") {
      setPortalLinkError("URL must start with http:// or https://");
    } else {
      setPortalLinkError("");
    }
    setPortalLink(value);
  };

  // Save changes from modal to main state
  const handleSaveLinks = () => {
    setSocialMediaLinks(tempLinks);
    setShowModal(false);
  };

  const isSaveButtonEnabled = tempLinks.every((link) => {
    return link.url === "" || link.url === null || link.error === "";
  });

  const handleUserNameChange = (event) => {
    const value = event.target.value;

    // Check if the value matches the required username format
    const isValidUsernameFormat = /^[a-z0-9]+$/.test(value);

    // Update the username value and error message based on validity
    if (!isValidUsernameFormat && value !== "") {
      setUserNameError(
        "Please enter a valid username (lowercase letters and numbers only, no spaces)"
      );
    } else {
      setUserNameError("");
    }

    setUsername(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    const isValidPasswordFormat = /^[a-zA-Z0-9@_\[\].,]+$/.test(value);

    if (!isValidPasswordFormat && value !== "") {
      setPasswordError(
        "Password must be alphanumeric, case-sensitive, no spaces, and may contain @, _, [, ] or ,"
      );
    } else {
      setPasswordError("");
    }

    setPassword(value);
  };

  const handleAgentNameChange = (event) => {
    const value = event.target.value;

    // Regex for alphanumeric, case-sensitive, with allowed special characters and spaces
    const isValidAgentNameFormat =
      /^(?! )[a-zA-Z0-9@.&()'\- ]{3,50}(?<! )$/.test(value);

    if (!isValidAgentNameFormat && value !== "") {
      setAgentNameError(
        "Agent Name can contain alphanumeric characters, spaces, and @, ., &, (, ), ', -"
      );
    } else {
      setAgentNameError("");
    }

    setAgentName(value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;

    // Check if the value matches the email format
    const isValidEmailFormat =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(value);

    // Reset error messages
    setEmailError("");

    if (!isValidEmailFormat) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    setEmail(value);
  };

  const handleAddressChange = (event) => {
    const value = event.target.value;

    // Regex to validate alphanumeric, case-sensitive, spaces, and specific special characters
    const isValidAddressFormat =
      /^(?! )[a-zA-Z0-9@.&()'\/\- ]{5,255}(?<! )$/.test(value);
    // console.log(isValidAddressFormat, "valid check");

    if (!isValidAddressFormat && value !== "") {
      setAddressError(
        "Address can contain alphanumeric characters, spaces, and @, ., &, (, ), #, -."
      );
    } else {
      setAddressError("");
    }

    setAddress(value);
  };

  const handleCompanyNameChange = (event) => {
    const value = event.target.value;

    // Regex for alphanumeric, case-sensitive, with allowed special characters and spaces
    const isValidAgentNameFormat =
      /^(?! )[a-zA-Z0-9@.&()'\- ]{3,50}(?<! )$/.test(value);

    if (!isValidAgentNameFormat && value !== "") {
      setCompanyNameError(
        "Agent Name can contain alphanumeric characters, spaces, and @, ., &, (, ), ', -"
      );
    } else {
      setCompanyNameError("");
    }

    setCompanyName(value);
  };

  useEffect(() => {
    axios
      .get(`${app_url}/api/partner/getDiscountPlans_for_partner`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // toast.success(response.data.message);
        setDiscountPlan(response?.data?.discountPlans);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error(
            error?.response?.data?.message ||
              "Network error. Please check your internet connection."
          );
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${app_url}/api/partner/get_account_manager_for_partner`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // toast.success(response.data.message);
        setPartnerManager(response?.data?.accountManager);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error(
            error?.response?.data?.message ||
              "Network error. Please check your internet connection."
          );
        }
      });
  }, []);

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    if (
      userNameError ||
      passwordError ||
      emailError ||
      agentNameError ||
      emailError ||
      addressError ||
      companyNameError ||
      portalLinkError
    ) {
      toast.error("Please fix the errors before submitting the form.");
      return;
    }
    const data = {
      username: username,
      password: password,
      agent_name: agentName,
      email: email,
      address: address,
      mobile: phone,
      company_name: companyName,
      company_phone: companyPhone,
      dplan_id: Number(selectedDiscountPlan),
      account_mode: selectedaccountMode,
      social_media_links: socialMediaLinks,
      cust_portal_url: portalLink,
      account_manager_id: selectedPartnerManager,
    };
    axios
      .post(`${app_url}/api/partner/registerNewPartner`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
            navigate("/");
          } else if (error.response.status === 500) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  // console.log(selectedDiscountPlan, "selected discount plan check");
  // console.log(phone, "phone check");
  // console.log(address, "address check");

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            Add Partner
          </p>
        </div>
      </div>
      <form autoComplete="off">
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Personal Information</p>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="userName" className="form-label">
                User Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="userName"
                onChange={handleUserNameChange}
                placeholder="Enter User Name"
                required
                maxLength={20}
                aria-label="userName"
                autoComplete="off"
              />
              {userNameError && (
                <div className="text-danger mt-1">{userNameError}</div>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="password" className="form-label">
                Password*
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={handlePasswordChange}
                placeholder="Enter Password"
                required
                maxLength={100}
                autoComplete="off"
              />
              {passwordError && (
                <div className="text-danger mt-1">{passwordError}</div>
              )}
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="agentName" className="form-label">
                Agent Name
              </label>
              <input
                type="text"
                className="form-control"
                id="agentName"
                onChange={handleAgentNameChange}
                placeholder="Enter Agent Name"
                maxLength={50}
                required
              />
              {agentNameError && (
                <small className="text-danger mt-1">{agentNameError}</small>
              )}
            </div>

            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
                maxLength={30}
                required
              />
              {emailError && (
                <div className="text-danger mt-1">{emailError}</div>
              )}
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Enter Address"
                maxLength={255}
                onChange={handleAddressChange}
              />
              {addressError && (
                <small className="text-danger mt-1">{addressError}</small>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                country={"us"} // Default country code
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="discountPlan" className="form-label">
                Discount Plan
              </label>
              <select
                className="border rounded px-2 py-1"
                onChange={(e) =>
                  setSelectedDiscountPlan(Number(e.target.value))
                }
                required
              >
                <option value="" disabled selected>
                  Select Discount Plan
                </option>
                {discountPlan?.map((plan) => (
                  <option key={plan?.id} value={plan?.id}>
                    {plan?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="accountMode" className="form-label">
                Account Mode
              </label>
              <select
                className="border rounded px-2 py-1"
                onChange={(e) => setSelectedAccountMode(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select Account Mode
                </option>
                {accountMode.map((mode) => (
                  <option key={mode.id} value={mode.name}>
                    {mode?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="companyName" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                placeholder="Enter Company Name"
                maxLength={100}
                onChange={handleCompanyNameChange}
              />
              {companyNameError && (
                <small className="text-danger mt-1">{companyNameError}</small>
              )}
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="phoneNumber" className="form-label">
                Company Phone Number
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: false,
                  autoFocus: true,
                }}
                country={"us"} // Default country code
                value={companyPhone}
                onChange={(phone) => setCompanyPhone(phone)}
                // containerClass="form-control"
              />
            </div>
          </div>
          <div className="row mt-sm-3 mt-0">
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <label htmlFor="portalUrl" className="form-label">
                Customer Portal Url
              </label>
              <input
                type="text"
                className="form-control"
                id="portalUrl"
                placeholder="Enter Portal Url"
                maxLength={100}
                onChange={handlePortalUrl}
              />

              {portalLinkError && (
                <small className="text-danger mt-1">{portalLinkError}</small>
              )}
              {/* Note for URL reference */}
              <div>
                <small className="text-muted">
                  <b>Note:</b> The URL must start with http:// or https://.
                  Example: https://example.com
                </small>
              </div>
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3 d-flex flex-column">
              <label htmlFor="partnerManager" className="form-label">
                Account Manager
              </label>
              <select
                className="border rounded px-2 py-1"
                onChange={(e) =>
                  setSelectedPartnerManager(Number(e.target.value))
                }
                required
              >
                <option value="" disabled selected>
                  Select Account Manager
                </option>
                {partnerManager?.map((manager) => (
                  <option key={manager?.id} value={manager?.id}>
                    {manager?.full_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="fw-bold fs-4 mb-0">Social Media Links</p>
          <div className="row">
            {socialMediaLinks.map((link, index) => (
              <div className="col-sm-6 col-12" key={link.slug}>
                <label
                  htmlFor={link.slug}
                  className="form-label d-flex gap-1 mt-3"
                >
                  <img
                    src={
                      link.slug === "youtube"
                        ? youtubeIcon
                        : link.slug === "facebook"
                        ? facebookIcon
                        : link.slug === "instagram"
                        ? instagramIcon
                        : link.slug === "tiktok"
                        ? tiktokIcon
                        : webIcon
                    }
                    alt={`${link.name} icon`}
                    width={20}
                    height={20}
                  />
                  {link.name}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={link.slug}
                  placeholder={`${link.name} link`}
                  disabled
                  value={link.url}
                  maxLength={1500}
                />
              </div>
            ))}
            <div className="col-sm-6 col-12" style={{ marginTop: "25px" }}>
              <button
                type="button"
                className="btn card-btn mt-3 w-100 d-flex align-items-center justify-content-center"
                onClick={handleModalShow}
              >
                <i className="bi bi-plus text-white fs-5"></i> Add Social Media
                Links
              </button>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <button
            type=" submit"
            className="btn primary-btn mt-4 px-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      {/* Modal for Social Media Links */}
      {showModal && (
        <SocialMediaLinksModal
          handleModalClose={handleModalClose}
          handleUrlChange={handleUrlChange}
          tempLinks={tempLinks}
          handleSaveLinks={handleSaveLinks}
          isSaveButtonEnabled={isSaveButtonEnabled}
        />
      )}
    </section>
  );
};

export default AddPartner;
