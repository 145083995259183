import React from "react";
import { useNavigate } from "react-router-dom";

const AffiliatePartnersTable = ({ tableData, isLoading }) => {
  const navigate = useNavigate();
  const newArr = new Array(12).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  console.log(isLoading, " check");
  console.log(tableData, " check");

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-5 ps-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-5">
            {item}
          </th>
          <th scope="row" className="text-center py-5">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  const handleRowClick = (id) => {
    navigate(`/affiliatePartners/${id}`);
  };

  console.log(tableData?.data?.result?.length, "length check");

  return (
    <div className="table-responsive">
      <table className="table mt-4 table-tb">
        <thead className="">
          <tr className="rounded">
            <th className="tr-tt fw-bold ps-5" scope="col">
              User Information
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Commission & Discounts
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Banking Information
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Registered at
            </th>
          </tr>
        </thead>
        {isLoading ? (
          skeleton
        ) : (
          <tbody>
            {tableData?.data?.result?.length == 0 ? (
              <tr>
                <th className="py-4"></th>
                <th className="py-4 align-middle">No Data Found</th>
                <th className="py-4"></th>
                <th className="py-4"></th>
              </tr>
            ) : (
              tableData?.data?.result?.map(
                (
                  {
                    bank_account_number,
                    bank_account_title,
                    bank_name,
                    commision_rate,
                    country_of_residence,
                    created_at,
                    discount_percentage,
                    full_name,
                    email,
                    id,
                    nationality,
                    phone_number,
                    social_media_links,
                  },
                  index
                ) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(id)}
                    key={index}
                  >
                    <th className="ps-5 align-middle">
                      <div>
                        <b className="fw-semibold">Name: </b>
                        {full_name} <br />
                        <b className="fw-semibold">Email: </b>
                        <span className="text-lowercase">{email}</span> <br />
                        <b className="fw-semibold">Phone: </b>
                        {phone_number === null ? "N/A" : phone_number}
                      </div>
                    </th>
                    <th className="align-middle">
                      <div>
                        <b className="fw-semibold">Comission: </b>{" "}
                        {commision_rate === null ? "N/A" : `${commision_rate}%`}
                        <br />
                        <b className="fw-semibold">Discount: </b>
                        {discount_percentage === null
                          ? "N/A"
                          : `${discount_percentage}%`}
                      </div>
                    </th>
                    <th className="align-middle">
                      <div>
                        <b className="fw-semibold">Bank Name: </b>
                        {bank_name === null ? "N/A" : `${bank_name}`}
                        <br />
                        <b className="fw-semibold">Acc Title: </b>{" "}
                        {bank_account_title === null
                          ? "N/A"
                          : bank_account_title}
                        <br />
                        <b className="fw-semibold">Acc Number: </b>
                        {bank_account_number === null
                          ? "N/A"
                          : bank_account_number}
                      </div>
                    </th>
                    <th className="align-middle">
                      <div>
                        {created_at === null
                          ? "N/A"
                          : new Date(created_at).toLocaleString()}
                      </div>
                    </th>
                  </tr>
                )
              )
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AffiliatePartnersTable;
