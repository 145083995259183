import ExistingModal from "../../components/modals/ExistingModal";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { app_url } from "../../config";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import axios from "axios";
import AddNetworkCoverageModal from "../../components/modals/AddNetworkCoverageModal";
import DeleteNetworkCoverageModal from "../../components/modals/DeleteNetworkCoverageModal";
import Error500 from "../../components/500/Error500";

const Global = () => {
  const { uid } = useParams();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existing, setExisting] = useState(null);
  const [nonExisting, setNonExisting] = useState(null);
  const [removedCountries, setRemovedCountries] = useState([]);
  const [addCountries, setAddCountries] = useState([]);
  const [res, setRes] = useState(null);
  const [imgUpdate, setImageUpdated] = useState("");
  const [online, setOnline] = useState(false);
  const [failed, setFailed] = useState("");
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [planTypeData, setPlanTypeData] = useState([]);
  const [networkCoverages, setNetworkCoverages] = useState([]);
  const [countryCoverages, setCountryCoverages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPlanTypeLoading, setModalPlanTypeLoading] = useState(false);
  const [modalNetworkCoverageLoading, setModalNetworkCoverageLoading] =
    useState(false);
  const [selectedNetworkCoverage, setSelectedNetworkCoverage] = useState("");
  const [networkCoverageDetails, setNetworkCoverageDetails] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteNetworkCoverageId, setDeleteNetworkCoverageId] = useState(null);
  const [addSupportedCountriesLoader, setAddSupportedCountriesLoader] =
    useState(false);
  const [addCarrier, setAddCarrier] = useState(null);
  const [removeSupportedCountriesLoader, setRemoveSupportedCountriesLoader] =
    useState(false);
  const [removeCarrier, setRemoveCarrier] = useState(null);
  const [addCountryLoader, setAddCountryLoader] = useState(false);
  const [supportedCountries, setSupportedCountries] = useState([]);
  const [removeCountryLoader, setRemoveCountryLoader] = useState(false);
  const [removeSupportedCountries, setRemoveSupportedCountries] = useState([]);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  // Router
  const navigate = useNavigate();

  const addSupportedCountries = () => {
    setAddSupportedCountriesLoader(true);
    const dataToSend = {
      operationSlug: "add",
      categorySlug: "global",
      carrierProviderId: addCarrier?.value,
      data: addCountries,
    };
    axios
      .post(
        app_url +
          "/api/country/remove_or_add_local_countries_for_regional_or_global_by_data_array",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAddCountries([]);
        setAddSupportedCountriesLoader(false);
        window.location.reload();
      })
      .catch((err) => {
        setAddSupportedCountriesLoader(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  const removeSupportedCountry = () => {
    setRemoveSupportedCountriesLoader(true);
    const dataToSend = {
      operationSlug: "remove",
      categorySlug: "global",
      carrierProviderId: removeCarrier?.value,
      data: removedCountries,
    };
    axios
      .post(
        app_url +
          "/api/country/remove_or_add_local_countries_for_regional_or_global_by_data_array",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRemovedCountries([]);
        setRemoveSupportedCountriesLoader(false);
        window.location.reload();
      })
      .catch((err) => {
        setRemoveSupportedCountriesLoader(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  const carrierOptions = carriers?.map((carrier) => ({
    label: carrier.carrierName,
    value: carrier.id,
  }));

  console.log(carrierOptions, "carrier options check");

  console.log(addCarrier, "selected carrier check");
  console.log(removeCarrier, "selected remove carrier check");

  // useEffect to watch addCarrier state
  useEffect(() => {
    console.log("useEffect triggered. Current addCarrier:", addCarrier);

    if (addCarrier?.value) {
      console.log(
        "Carrier is now selected. Calling addCarriers...",
        addCarrier.value
      );

      addCarriers();
    }
  }, [addCarrier]);

  // useEffect to watch removeCarrier state
  useEffect(() => {
    console.log("useEffect triggered. Current addCarrier:", addCarrier);

    if (removeCarrier?.value) {
      console.log(
        "Carrier is now selected. Calling addCarriers...",
        removeCarrier.value
      );

      removeCarriers();
    }
  }, [removeCarrier]);

  console.log(addCountries, "add countries data check");

  const addCarriers = () => {
    if (addCarrier?.value) {
      setAddCountries([]);
      setAddCountryLoader(false);
      console.log(addCarrier, "add Carriers Trigger");
      axios
        .get(
          `${app_url}/api/country/getSupportedCountriesFromCarrierProviderOrSystem?carrierProviderId=${addCarrier?.value}&slug=global&regionId=&operationSlug=add`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setSupportedCountries(res.data?.supportedCountries);
          setAddCountryLoader(true);
          console.log(res.data, "carriers remove supported country");
        })
        .catch((err) => {
          setAddCountryLoader(false);
          if (err.response) {
            if (err.response.status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (err.response.status === 401) {
              navigate("/");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error("An unexpected error occurred.");
            }
          } else {
            setOnline(true);
            toast.error(
              "Network error. Please check your internet connection."
            );
            setFailed("Network error. Please check your internet connection.");
          }
        });
    } else {
      toast.error("No carrier selected");
    }
  };

  const removeCarriers = () => {
    if (removeCarrier?.value) {
      setRemovedCountries([]);
      setRemoveCountryLoader(false);
      console.log(removeCarrier, "removeCarrier Carriers Trigger");
      axios
        .get(
          `${app_url}/api/country/getSupportedCountriesFromCarrierProviderOrSystem?carrierProviderId=${removeCarrier?.value}&slug=global&regionId=&operationSlug=remove`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setRemoveSupportedCountries(res.data?.supportedCountries);
          setRemoveCountryLoader(true);
          console.log(res.data, "carriers supported country");
        })
        .catch((err) => {
          setRemoveCountryLoader(false);
          if (err.response) {
            if (err.response.status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (err.response.status === 401) {
              navigate("/");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error("An unexpected error occurred.");
            }
          } else {
            setOnline(true);
            toast.error(
              "Network error. Please check your internet connection."
            );
            setFailed("Network error. Please check your internet connection.");
          }
        });
    } else {
      toast.error("No carrier selected");
    }
  };

  const AddCountries = (slug) => {
    setLoading(true);
    const dataToSend = { slug: slug, uid: uid, data: addCountries };
    if (addCountries.length === 0) {
      toast.error("Please Add Country");
      return;
    }
    axios
      .post(
        app_url +
          "/api/country/remove_or_add_local_countries_for_global_by_array",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAddCountries([]);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  const removeCountries = (slug) => {
    if (removedCountries.length === 0) {
      toast.error("Please Select Countries to remove");
      return;
    }
    setLoading(true);
    const dataToSend = { slug: slug, uid: uid, data: removedCountries };
    axios
      .post(
        app_url +
          "/api/country/remove_or_add_local_countries_for_global_by_array",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setRemovedCountries([]);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  // Get the Selected Country
  useEffect(() => {
    setLoading(true);
    const url = app_url + `/api/country/getGlobalCountries`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setApiData(res.data?.region);
        setCarriers(res?.data?.carriers);
        setCountryCoverages(res?.data?.coverages);
        setRes(res.data);
        setExisting(res.data?.existedLocals);
        setNonExisting(res.data?.nonExistingLocals);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  }, []);

  const getNetworkCoverage = (selectedPlanType) => {
    setModalNetworkCoverageLoading(true);
    const url =
      app_url +
      `/api/country/getCoveragesFromCarrierProvider?carrierProviderId=${
        carriers[0]?.id
      }&slug=${"global"}&countryCode=${""}&plan_type=${selectedPlanType}&regionSlug=${""}`;
    // console.log("Request URL:", url);
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setNetworkCoverages(res?.data?.coverages);
        setModalNetworkCoverageLoading(false);
      })
      .catch((err) => {
        setModalNetworkCoverageLoading(false);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  // useEffect to watch select carrier provider state for add network coverage modal
  useEffect(() => {
    console.log(
      "useEffect triggered. Current selected carrier:",
      selectedCarrier
    );

    if (selectedCarrier) {
      console.log(
        "Carrier is now selected. Calling getPlanType...",
        selectedCarrier
      );

      getPlanType();
    }
  }, [selectedCarrier]);

  console.log(selectedPlanType, "plan type check");
  console.log(modalPlanTypeLoading, "modal loading check");

  const getPlanType = () => {
    setPlanTypeData([]);
    setNetworkCoverages([]);
    setModalPlanTypeLoading(true);
    setSelectedPlanType("");
    setSelectedNetworkCoverage("");
    const url =
      app_url +
      `/api/country/getCoveragesTypeFromCarrierProvider?carrierProviderId=${selectedCarrier}&slug=${"global"}&countryCode=${""}&regionSlug=${""}`;
    // console.log("Plan Type Requeast Url:", url);
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setPlanTypeData(res?.data?.plans);
        // console.log(res?.data?.plans, "plan type data check");
        setNetworkCoverageDetails(null);
        setModalPlanTypeLoading(false);
      })
      .catch((err) => {
        setPlanTypeData([]);
        setNetworkCoverages([]);
        setModalPlanTypeLoading(false);
        setSelectedPlanType("");
        setSelectedNetworkCoverage("");
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  // console.log(modalLoading, "check modal loading");
  // function to set selected network: countryCode, planType and networkName
  const handleNetworkCoverageChange = (e) => {
    const selectedNetworkName = e.target.value;
    setSelectedNetworkCoverage(selectedNetworkName);

    let selectedCoverage = null;
    let selectedNetwork = null;

    networkCoverages.forEach((coverage) => {
      const network = coverage.networks.find(
        (net) => net.name === selectedNetworkName
      );
      if (network) {
        selectedCoverage = coverage;
        selectedNetwork = network;
      }
    });

    if (selectedCoverage && selectedNetwork) {
      setNetworkCoverageDetails({
        networkName: selectedNetworkName,
        country_code: selectedCoverage.name,
        networkTypes: selectedNetwork.types,
      });
    }
  };

  // console.log(
  //   networkCoverageDetails,
  //   "selected Network Coverage Details check"
  // );

  // Function to handle modal close
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle modal open
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCarrier("");
    setSelectedPlanType("");
    setNetworkCoverages([]);
    setSelectedNetworkCoverage("");
    setNetworkCoverageDetails(null);
  };

  const confirmDelete = () => {
    axios
      .delete(
        `${app_url}/api/country/remove_nc_by_id?id=${deleteNetworkCoverageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setShowDeleteModal(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          setOnline(true);
          toast.error("Network error. Please check your internet connection.");
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  const handleDelete = (id) => {
    // console.log("Deleting ID:", id);
    setDeleteNetworkCoverageId(id);
    setShowDeleteModal(true);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  if (online) {
    return <Error500 message={failed} />;
  }

  return (
    <section className="wrapper d-flex align-content-center justify-content-center">
      <div className="row w-100 justify-content-center">
        {/* {loading ? (
          <div className="bg-transparent d-flex align-items-center">
            <Loader />
          </div>
        ) : ( */}
        <div className="col-lg-12 col-xl-8 col-md-10 col-sm-12">
          <div className="bg-white rounded p-4">
            <form style={{ marginBottom: "2em" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-4 d-flex align-items-center justify-content-between">
                    <h1>Global</h1>
                    <button
                      type="button"
                      className="btn border"
                      data-bs-toggle="modal"
                      data-bs-target="#scrollableModal"
                    >
                      {existing?.length === 1
                        ? existing?.length + " " + "Country"
                        : existing?.length + " " + "Countries"}
                    </button>
                  </div>
                </div>
                {/* <===== Add Countries =====> */}
                <div className="mt-4">
                  <h4 className="mb-0">Add Countries</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        name="carrier"
                        options={carrierOptions}
                        placeholder="Select Carrier Provider"
                        className="basic-multi-select mt-3 mb-3"
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          setAddCarrier(selectedOption);
                          console.log(
                            selectedOption,
                            "selected carrier option check"
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        isMulti
                        name="colors"
                        options={supportedCountries}
                        value={addCountries}
                        isDisabled={!addCountryLoader}
                        placeholder="Add supported countries"
                        className="basic-multi-select mt-3 mb-3"
                        classNamePrefix="select"
                        onChange={(Selected) => {
                          setAddCountries(Selected);
                        }}
                      />
                      <button
                        type="button"
                        disabled={addCountries.length === 0}
                        className={`${
                          addCountries.length === 0 ? "d-none" : "d-block"
                        } btn btn-sm bg-success text-white shadow rounded my-3`}
                        onClick={() => addSupportedCountries()}
                      >
                        Click to Add Selected
                        {addSupportedCountriesLoader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <===== Remove Countries =====> */}
                <div className="mt-4">
                  <h4 className="mb-0">Remove Countries</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        name="carrier"
                        options={carrierOptions}
                        placeholder="Select Carrier Provider"
                        className="basic-multi-select mt-3 mb-4 mb-md-0"
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          setRemoveCarrier(selectedOption);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        isDisabled={!removeCountryLoader}
                        isMulti
                        name="colors"
                        options={removeSupportedCountries}
                        value={removedCountries}
                        placeholder="Remove Supported Countries"
                        className="basic-multi-select mt-3 mb-4 mb-md-0"
                        classNamePrefix="select"
                        onChange={(Selected) => {
                          setRemovedCountries(Selected);
                        }}
                      />
                      <button
                        type="button"
                        disabled={removedCountries.length === 0}
                        className={`${
                          removedCountries.length === 0 ? "d-none" : "d-block"
                        } btn btn-sm bg-danger text-white shadow rounded my-3`}
                        onClick={() => {
                          removeSupportedCountry();
                        }}
                      >
                        Click to Remove Selected
                        {removeSupportedCountriesLoader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <Select
                    isMulti
                    name="colors"
                    options={existing}
                    placeholder="Remove supported countries"
                    className="basic-multi-select mt-3 mb-4 mb-md-0"
                    classNamePrefix="select"
                    onChange={(Selected) => {
                      setRemovedCountries(Selected);
                    }}
                  />
                  <button
                    disabled={removedCountries.length === 0}
                    className={`${
                      removedCountries.length === 0 ? "d-none" : "d-block"
                    } btn btn-sm bg-danger text-white shadow rounded  my-3`}
                    onClick={() => {
                      removeCountries("removed");
                    }}
                  >
                    Click to Remove Selected
                  </button>
                </div>
                <div className="col-md-6">
                  <Select
                    isMulti
                    name="colors"
                    options={nonExisting}
                    placeholder="Add supported countries"
                    className="basic-multi-select mt-3 mb-4 mb-md-0"
                    classNamePrefix="select"
                    onChange={(Selected) => {
                      setAddCountries(Selected);
                    }}
                  />
                  <button
                    disabled={addCountries.length === 0}
                    className={`${
                      addCountries.length === 0 ? "d-none" : "d-block"
                    } btn btn-sm bg-success text-white shadow rounded my-3`}
                    onClick={() => AddCountries("add")}
                  >
                    Click to Add Selected
                  </button>
                </div> */}
              </div>
            </form>
            <hr
              className="border-top-2 border-bottom-2"
              style={{ padding: "8px" }}
            />
            <h4 className="mb-3">Network Coverages</h4>
            {loading ? (
              <div className="bg-transparent d-flex align-items-center">
                <Loader />
              </div>
            ) : countryCoverages?.length === 0 ? (
              <div>No Data Found</div>
            ) : (
              <table className=" table table-bordered">
                <thead className="">
                  <tr>
                    <th>Country</th>
                    <th>Plan Type</th>
                    <th>Network Name</th>
                    <th>Network Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {countryCoverages.map((coverage, index) => (
                    <React.Fragment key={index}>
                      {coverage.networks?.map((item, networkIndex) => (
                        <tr key={networkIndex}>
                          {networkIndex === 0 && (
                            <td
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                              rowSpan={coverage.networks.length}
                            >
                              {coverage.country_code}
                            </td>
                          )}
                          <td>{item.plan_type}</td>
                          <td>{item.network_name}</td>
                          <td>{JSON.parse(item.network_types).join(", ")}</td>
                          <td style={{ color: "red" }}>
                            <i
                              className="bi bi-trash text-danger fs-5 d-flex"
                              onClick={() => handleDelete(item.id)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
            <div
              style={{
                marginTop: "2em",
                justifyContent: "right",
                display: "flex",
              }}
            >
              <button className="btn card-btn" onClick={handleOpenModal}>
                Add Network Coverage
              </button>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
      <ExistingModal data={existing} />
      <AddNetworkCoverageModal
        setSelectedCarrier={setSelectedCarrier}
        carriers={carriers}
        selectedCarrier={selectedCarrier}
        setSelectedPlanType={setSelectedPlanType}
        getNetworkCoverage={getNetworkCoverage}
        selectedPlanType={selectedPlanType}
        networkCoverages={networkCoverages}
        show={showModal}
        onHide={handleCloseModal}
        loading={modalLoading}
        selectedNetworkCoverage={selectedNetworkCoverage}
        setSelectedNetworkCoverage={setSelectedNetworkCoverage}
        planTypeData={planTypeData}
        modalPlanTypeLoading={modalPlanTypeLoading}
        handleNetworkCoverageChange={handleNetworkCoverageChange}
        networkCoverageDetails={networkCoverageDetails}
        modalNetworkCoverageLoading={modalNetworkCoverageLoading}
      />
      <DeleteNetworkCoverageModal
        show={showDeleteModal}
        onHide={cancelDelete}
        onConfirm={confirmDelete}
      />
    </section>
  );
};

export default Global;
