import axios from "axios";
import React, { useState } from "react";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import { FaEdit, FaEye, FaEyeSlash } from "react-icons/fa";

const PortalUserTable = ({
  tableData,
  isLoading,
  handleApiError,
  fetchPortalUsers,
}) => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [activeToggle, setActiveToggle] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    field: "",
    value: "",
  });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [validationError, setValidationError] = useState("");
  const nameRegex = /^[A-Za-z]+(?:[-'][A-Za-z]+)?$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
  const passwordRegex = /^[a-zA-Z0-9@_\[\].,]+$/;
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const newArr = new Array(12).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const search = "";
  const selectedUserType = "all";

  const updatePrivileges = (id, slug, value) => {
    const fieldMap = {
      userType: "userType",
      updateFirstName: "first_name",
      updateLastName: "last_name",
      updateEmail: "email",
    };

    const fieldName = fieldMap[slug] || slug; // Default to slug if no mapping found
    setActiveToggle(true);
    axios
      .post(
        `${app_url}/api/portalUser/privilegesOnAdminPortalUsers`,
        {
          id,
          slug,
          [fieldName]: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setActiveToggle(false);
          fetchPortalUsers(search, selectedUserType);
        }
      })
      .catch((err) => {
        fetchPortalUsers(search, selectedUserType);
        handleApiError(err);
        setActiveToggle(false);
      });
  };

  const validateInput = (field, value) => {
    if (field === "updateFirstName" || field === "updateLastName") {
      return nameRegex.test(value)
        ? ""
        : "Name can only include letters and optional hyphens or apostrophes.";
    }
    if (field === "updateEmail") {
      return emailRegex.test(value)
        ? ""
        : "Please enter a valid email address.";
    }
    return "";
  };

  const handleModalSave = () => {
    const error = validateInput(modalData.field, modalData.value);
    if (error) {
      setValidationError(error);
      return;
    }

    // Proceed with the update if validation passes
    updatePrivileges(modalData.id, modalData.field, modalData.value);
    setModalData({ id: "", field: "", value: "" });
    setValidationError("");
    toggleEditInfoModal();
  };

  const toggleEditInfoModal = (id, field, currentValue) => {
    setModalData({ id, field, value: currentValue });
    setShowInfoModal((prev) => !prev);
  };

  const cancelDelete = () => {
    toggleEditInfoModal();
    setValidationError("");
  };

  const handleOpenPasswordModal = (id) => {
    setSelectedUserId(id);
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    setNewPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmPasswordError("");
  };

  console.log(passwordError, "password check");

  const handleUpdatePassword = () => {
    const isValidPasswordFormat = passwordRegex.test(newPassword);
    if (!isValidPasswordFormat && newPassword !== "") {
      setPasswordError(
        "Password must contain only letters, numbers, and @, _, [, ], ., or ,."
      );
      return;
    } else {
      setPasswordError("");
    }

    // Validate confirm password
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    } else {
      setConfirmPasswordError("");
    }

    axios
      .post(
        `${app_url}/api/portalUser/updatePassForAdminPortalUsers`,
        { id: selectedUserId, password: newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        if (res.data.success) {
          setShowPasswordModal(false);
          toast.success(res.data?.message || "Password updated successfully!");
          handleClosePasswordModal("");
          fetchPortalUsers(search, selectedUserType);
        } else {
          toast.error("Failed to update password.");
        }
      })
      .catch((err) => {
        handleClosePasswordModal("");
        fetchPortalUsers(search, selectedUserType);
        handleApiError(err);
      });
  };
  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-5 ps-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-5">
            {item}
          </th>
          <th scope="row" className="text-center py-5">
            {item}
          </th>
          <th scope="row" className="text-center py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className="table-responsive">
      <table className="table mt-4 table-tb">
        <thead className="">
          <tr className="rounded">
            <th className="tr-tt fw-bold ps-5" scope="col">
              Id
            </th>
            <th className="tr-tt fw-bold" scope="col">
              User Information
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Address
            </th>
            <th className="tr-tt fw-bold" scope="col">
              User Role
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Last Active
            </th>
            <th className="tr-tt fw-bold" scope="col">
              isActive
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Registered at
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Password
            </th>
          </tr>
        </thead>
        {isLoading ? (
          skeleton
        ) : (
          <tbody>
            {tableData?.data?.result?.length == 0 ? (
              <tr>
                <th className="py-4"></th>
                <th className="py-4"></th>
                <th className="py-4"></th>
                <th className="py-4"></th>
                <th className="py-4 align-middle">No Data Found</th>
                <th className="py-4"></th>
                <th className="py-4"></th>
                <th className="py-4"></th>
                <th className="py-4"></th>
              </tr>
            ) : (
              tableData?.data?.result?.map(
                (
                  {
                    id,
                    first_name,
                    last_name,
                    email,
                    state,
                    city,
                    mobile,
                    country,
                    isActive,
                    ipAddress,
                    lastAuthAt,
                    userType,
                    createdAt,
                  },
                  index
                ) => {
                  const isoDate = lastAuthAt;
                  const date = new Date(isoDate);

                  // Get local date and time
                  const localDate = date.toLocaleDateString();
                  const localTime = date.toLocaleTimeString();
                  return (
                    <>
                      <tr>
                        <th className="ps-5  align-middle">
                          <div>{id}</div>
                        </th>
                        <th className="align-middle">
                          <div>
                            <b className="fw-semibold">First Name: </b>
                            {first_name}{" "}
                            <button
                              type="button"
                              onClick={() =>
                                toggleEditInfoModal(
                                  id,
                                  "updateFirstName",
                                  first_name
                                )
                              }
                              className="btn px-1 py-0 mb-1"
                              style={{ right: "5px" }}
                              title="edit first name"
                            >
                              <FaEdit />
                            </button>
                            <br />
                            <b className="fw-semibold">Last Name: </b>
                            {last_name}{" "}
                            <button
                              type="button"
                              onClick={() =>
                                toggleEditInfoModal(
                                  id,
                                  "updateLastName",
                                  last_name
                                )
                              }
                              className="btn px-1 py-0 mb-1"
                              style={{ right: "5px" }}
                              title="edit last name"
                            >
                              <FaEdit />
                            </button>
                            <br />
                            <b className="fw-semibold">Email: </b>
                            <span className="text-lowercase">
                              {" "}
                              {email}
                            </span>{" "}
                            <button
                              type="button"
                              onClick={() =>
                                toggleEditInfoModal(id, "updateEmail", email)
                              }
                              className="btn px-1 py-0 mb-1"
                              style={{ right: "5px" }}
                              title="edit email"
                            >
                              <FaEdit />
                            </button>{" "}
                            <br />
                            <b className="fw-semibold">Phone: </b>
                            {mobile}
                          </div>
                        </th>
                        <th className="align-middle">
                          <div>
                            <b className="fw-semibold">City: </b>
                            {city} <br />
                            <b className="fw-semibold">State: </b>
                            {state}
                            <br />
                            <b className="fw-semibold">Country: </b>
                            {country}
                          </div>
                        </th>
                        <th className="align-middle">
                          <select
                            value={userType}
                            className="border rounded px-2 py-1 "
                            id="userType"
                            required
                            onChange={(e) =>
                              updatePrivileges(id, "userType", e.target.value)
                            }
                            title="update user role"
                          >
                            <option value="" disabled>
                              Select User Type
                            </option>
                            <option value="superadmin">Super Admin</option>
                            <option value="admin">Admin</option>
                            <option value="support">Support</option>
                            <option value="marketing">Marketing</option>
                            <option value="billing">Billing</option>
                            <option value="sales">Sales</option>
                          </select>
                        </th>
                        <th className="align-middle">
                          <div>
                            <b className="fw-semibold">Ip Address: </b>
                            {ipAddress} <br />
                            <b className="fw-semibold">Time: </b>
                            {`${localDate}, ${localTime}`}
                          </div>
                        </th>
                        <th className="align-middle">
                          <div className="form-check form-switch mt-0 me-2">
                            <input
                              style={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                              }}
                              className={`form-check-input active-unactive-pkg ${
                                isActive === 0 ? "unactive-pkg" : "active-pkg"
                              }`}
                              onChange={() => updatePrivileges(id, "isActive")}
                              defaultChecked={isActive === 1 ? true : false}
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              disabled={activeToggle}
                            />
                          </div>
                        </th>
                        <th className="align-middle">
                          <div>
                            {createdAt === null
                              ? "N/A"
                              : new Date(createdAt).toLocaleString()}
                          </div>
                        </th>
                        <th className="align-middle">
                          <button
                            variant="link"
                            className="btn card-btn py-2"
                            title="edit password"
                            onClick={() => handleOpenPasswordModal(id)}
                          >
                            Update Password
                          </button>
                        </th>
                      </tr>
                    </>
                  );
                }
              )
            )}
          </tbody>
        )}
      </table>
      {/* Modal for Update Information */}
      <div
        className={`modal fade ${showInfoModal ? "show" : ""}`}
        style={{ display: showInfoModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Information</h5>
            </div>
            <div className="modal-body">
              <label htmlFor="updateValue" className="form-label">
                Enter New Value
              </label>
              <input
                type="text"
                id="updateValue"
                className={`form-control ${
                  validationError ? "is-invalid" : ""
                }`}
                value={modalData.value}
                onChange={(e) => {
                  setModalData((prev) => ({ ...prev, value: e.target.value }));
                  setValidationError(
                    validateInput(modalData.field, e.target.value)
                  );
                }}
              />
              {validationError && (
                <div className="invalid-feedback">{validationError}</div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleModalSave}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showInfoModal && <div className="modal-backdrop fade show"></div>}

      {/* Password Update Modal */}
      <div
        className={`modal fade ${showPasswordModal ? "show" : ""}`}
        style={{ display: showPasswordModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="updatePasswordModalLabel">
                  Update Password
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClosePasswordModal}
                ></button>
              </div>
              <div className="modal-body">
                <form autoComplete="off">
                  <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">
                      New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        id="newPassword"
                        className="form-control"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        autoComplete="off"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setShowNewPassword((prev) => !prev)}
                      >
                        {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    {passwordError && (
                      <div className="text-danger mt-2">{passwordError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="retypeNewPassword" className="form-label">
                      Retype New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="retypeNewPassword"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        autoComplete="off"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    {confirmPasswordError && (
                      <div className="text-danger mt-2">
                        {confirmPasswordError}
                      </div>
                    )}
                  </div>
                  {validationError && (
                    <div className="text-danger">{validationError}</div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClosePasswordModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdatePassword}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPasswordModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default PortalUserTable;
