import React, { useEffect, useState } from "react";
import ProductUpdatesTable from "../../components/tables/ProductUpdatesTable";
import { app_url } from "../../config";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Error500 from "../../components/500/Error500";

const ProductUpdates = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [productUpdatesData, setProductUpdatesData] = useState([]);
  const [networkIssue, setNetworkIssue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState("");
  const navigate = useNavigate();

  const handleError = (err) => {
    setLoading(false);
    if (err.response) {
      if (err.response.status === 400) {
        toast.error(err.response.data.message || "Bad Request");
      } else if (err.response.status === 401) {
        navigate("/");
      } else if (err.response.status === 500) {
        toast.error(err.response.data.message || "Internal Server Error");
      } else {
        toast.error("An unexpected error occurred.");
      }
    } else {
      setNetworkIssue(true);
      toast.error("Network error. Please check your internet connection.");
      setFailed("Network error. Please check your internet connection.");
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${app_url}/api/productUpdate/getAllUpdatesForAportal`, {
        headers: {
          "content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProductUpdatesData(response?.data?.result);
        // console.log(response?.data?.result, "response check");
        setLoading(false);
      })
      .catch((err) => handleError(err));
  }, []);

  if (networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <section className="wrapper">
      <>
        <div className="d-flex flex-wrap gap-3 justify-content-between">
          <div className="d-flex justify-content-between ">
            <p className="heading">Product Updates</p>
          </div>
        </div>
        <ProductUpdatesTable
          tableData={productUpdatesData}
          isLoading={loading}
        />
      </>
    </section>
  );
};

export default ProductUpdates;
