import React from "react";
import Loader from "../Loader";

const EsimLineActivityModal = ({ isLoading, tableData }) => {
  console.log(isLoading, "loading check");

  return (
    <div className="table-responsive">
      <table className="table mt-4 table-tb">
        <thead>
          <tr className="rounded">
            <th className="tr-tt fw-bold ps-5" scope="col">
              LINEID
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Package Type
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Total Data
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Remaining Data
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Total Voice
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Remaining Voice
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Total Text
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Remaining Text
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Expiry
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Updated
            </th>
            <th className="tr-tt fw-bold" scope="col">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <th className="py-2 align-middle"></th>
              <th className="py-2"></th>
              <th colSpan="5" style={{ textAlign: "center", height: "10vh" }}>
                <Loader />
              </th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
            </tr>
          ) : tableData?.lineActivity?.length === 0 ? (
            <tr>
              <th className="py-2 align-middle ps-5"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2 align-middle ">No Line Id Found</th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
              <th className="py-2"></th>
            </tr>
          ) : (
            tableData?.lineActivity?.map(
              (
                {
                  id,
                  iccid,
                  lineId,
                  package_type,
                  total_voice,
                  remaining_voice,
                  totalData,
                  remainingData,
                  total_text,
                  remaining_text,
                  expires_at,
                  updated_at,
                  status,
                },
                index
              ) => (
                <tr key={index} width="300">
                  <th className="py-2 align-middle ps-5">{lineId}</th>
                  <th className="py-2 align-middle">{package_type}</th>
                  <th className="py-2 align-middle">{totalData}</th>
                  <th className="py-2 align-middle">{remainingData}</th>
                  <th className="py-2 align-middle">{total_voice}</th>
                  <th className="py-2 align-middle">{remaining_voice}</th>
                  <th className="py-2 align-middle">{total_text}</th>
                  <th className="py-2 align-middle">{remaining_text}</th>
                  <th className="py-2 align-middle">
                    {expires_at === null
                      ? "N/A"
                      : new Date(expires_at).toLocaleString()}
                  </th>
                  <th className="py-2 align-middle">
                    {updated_at === null
                      ? "N/A"
                      : new Date(updated_at).toLocaleString()}
                  </th>
                  <th className="py-2 align-middle">
                    <span
                      style={{
                        color:
                          status === "ACTIVE"
                            ? "#20af24"
                            : status === "NOT_ACTIVE"
                            ? "#FF0000"
                            : "#606060",
                        fontWeight: "600",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "25px",
                        background:
                          status === "ACTIVE"
                            ? "#e4fae5"
                            : status === "NOT_ACTIVE"
                            ? "#ffe7e7"
                            : " #EEE9E9",
                        padding: "6px 10px",
                        width: "8em",
                      }}
                    >
                      {status?.replace(/_/g, " ")}
                    </span>
                  </th>
                </tr>
              )
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EsimLineActivityModal;
