import React, { useState } from "react";
import esimDetailIcon from "../../../src/assets/images/e-sim.png";
import { MdOutlineShare } from "react-icons/md";
import { MdOutlineSignalCellularAlt, MdTrackChanges } from "react-icons/md";
import UsageModal from "../modals/UsageModal";
import { app_url } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import EsimDetailsModal from "../modals/EsimDetailsModal";
import Error500 from "../500/Error500";
import EsimLineActivityModal from "../modals/EsimLineActivityModal";

const EsimTable = ({ tabledata, isLoading }) => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showEsimDetailModal, setShowEsimDetailModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showLineActivityModal, setShowLineActivityModal] = useState(false);
  const [email, setEmail] = useState("");
  const [eSimDetail, seteSimDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedEsimData, setSelectedEsimData] = useState(null);
  const [eSimDetailLoader, seteSimDetailLoader] = useState(false);
  const [online, setOnline] = useState(false);
  const [failed, setFailed] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedIccid, setSelectedIccid] = useState("");

  console.log(eSimDetailLoader, "esim detail loader check in table");

  const handleCloseEsimModal = () => {
    setShowEsimDetailModal(false);
    setSelectedEsimData(null);
  };

  const handleOpneEsimModal = () => {
    setShowEsimDetailModal(true);
  };

  const geteSimDetail = (lineId) => {
    handleOpneEsimModal();
    seteSimDetailLoader(true);
    axios
      .get(
        `${app_url}/api/line/getLineDetailsByLineIdforAportal?lineId=${lineId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedEsimData(res?.data?.esim[0]);
        seteSimDetailLoader(false);
        console.log(res, "res check");
      })
      .catch((err) => {
        seteSimDetailLoader(false);
        handleCloseEsimModal();
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            toast.error(
              err.response.data.message ||
                "Unauthorized access. Please check your credentials. "
            );
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
            setFailed(
              "Server is currently unavailable. Please try again later."
            );
          } else {
            toast.error(
              err.response.data.message || "An unexpected error occurred."
            );
            setFailed(err.message);
          }
        } else {
          setOnline(true);
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  const newArr = new Array(12).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const handleClose = () => {
    setShowModal(false);
    seteSimDetail(null);
  };
  const handleShow = () => setShowModal(true);

  const handleResendEmail = () => {
    setShowEmailModal(true);
  };

  const handleResendEmailClose = () => {
    setShowEmailModal(false);
  };

  const handleSendEmail = () => {
    // console.log(email, "email send");
    setShowEmailModal(false);
  };

  const fetcheSimDetail = (lineID) => {
    handleShow();
    setLoading(true);
    axios
      .get(`${app_url}/api/line/getMyEsimByLineIdAport?lineId=${lineID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        seteSimDetail(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        handleClose();
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            toast.error(
              err.response.data.message ||
                "Unauthorized access. Please check your credentials. "
            );
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
            setFailed(
              "Server is currently unavailable. Please try again later."
            );
          } else {
            toast.error(
              err.response.data.message || "An unexpected error occurred."
            );
            setFailed(err.message);
          }
        } else {
          setOnline(true);
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  const hnadleLineActivityModalShow = () => {
    setShowLineActivityModal(true);
  };

  const hnadleLineActivityModlaClose = () => {
    setShowLineActivityModal(false);
    setTableData([]);
  };

  const fetchLineActivity = (lineID) => {
    setLoading(true);
    hnadleLineActivityModalShow();
    axios
      .post(
        `${app_url}/api/line/fetchLineActvityById_forAport`,
        { lineId: lineID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setTableData(res?.data);
        // console.log(res, "response check");
      })
      .catch((err) => {
        setLoading(false);
        hnadleLineActivityModlaClose();
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            toast.error(
              err.response.data.message ||
                "Unauthorized access. Please check your credentials. "
            );
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
            setFailed(
              "Server is currently unavailable. Please try again later."
            );
          } else {
            toast.error(
              err.response.data.message || "An unexpected error occurred."
            );
            setFailed(err.message);
          }
        } else {
          setOnline(true);
          setFailed("Network error. Please check your internet connection.");
        }
      });
  };

  console.log(tableData, "table data check");

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-5 ps-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-5">
            {item}
          </th>
          <th scope="row" className="text-center py-5">
            {item}
          </th>
          {/* <th scope="row" className="text-center py-5">
            {item}
          </th> */}
          {/* <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th> */}
        </tr>
      ))}
    </tbody>
  );

  if (online) {
    return <Error500 message={failed} />;
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table mt-4 table-tb">
          <thead className="">
            <tr className="rounded">
              <th className="tr-tt fw-bold ps-5" scope="col">
                eSIM
              </th>
              <th className="tr-tt fw-bold" scope="col">
                ICCID & ISSUE DATE
              </th>
              <th className="tr-tt fw-bold" scope="col">
                USER
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Actions
              </th>
            </tr>
          </thead>

          {isLoading ? (
            skeleton
          ) : (
            <tbody>
              {tabledata?.data?.result.length == 0 ? (
                <tr>
                  <th className="py-4"></th>
                  <th className="py-4"></th>
                  <th className="py-4 align-middle">No Data Found</th>
                  <th className="py-4"></th>
                  <th className="py-4"></th>
                </tr>
              ) : (
                tabledata?.data?.result.map(
                  (
                    {
                      id,
                      iccid,
                      carrierName,
                      image,
                      first_name,
                      last_name,
                      packageName,
                      email,
                      type,
                      createdAt,
                      agent_name,
                    },
                    index
                  ) => (
                    <tr className="">
                      <th className="py-4 align-middle esim_flag ps-5">
                        <div className="d-flex gap-2 align-items-center">
                          <img
                            src={app_url + image}
                            alt="flag image"
                            loading="lazy"
                          />
                          <p className="mb-0">
                            {" "}
                            {packageName} <br />({type})
                          </p>
                        </div>
                      </th>
                      <th className="py-4 align-middle">
                        <div>
                          {" "}
                          {iccid} <br /> {carrierName} <br />
                          {new Date(createdAt).toLocaleString()}
                        </div>
                      </th>
                      <th className="py-4 align-middle">
                        <div>
                          {first_name} {last_name}
                          <br />
                          <span className="text-lowercase">{email}</span>
                          <br />
                          {agent_name !== null && (
                            <>
                              <span className="fw-bold">Agent: </span>
                              {agent_name}
                            </>
                          )}
                        </div>
                      </th>
                      <th className="py-4 align-middle ">
                        <div className="d-flex gap-2">
                          <MdOutlineShare
                            size={28}
                            className="p-1 border border-dark rounded pointer"
                            style={{ borderRadius: "5px" }}
                            onClick={handleResendEmail}
                            title="send email"
                          />
                          <MdOutlineSignalCellularAlt
                            size={28}
                            className="p-1 border border-dark rounded pointer"
                            style={{ borderRadius: "5px" }}
                            onClick={() => {
                              fetcheSimDetail(id);
                            }}
                            title="esim usage"
                          />
                          <img
                            src={esimDetailIcon}
                            width={28}
                            height={28}
                            alt="detail icon"
                            className="p-1 border border-dark rounded pointer"
                            style={{ borderRadius: "5px" }}
                            onClick={() => {
                              geteSimDetail(id);
                            }}
                            title="esim details"
                          />
                          <MdTrackChanges
                            size={28}
                            className="p-1 border border-dark rounded pointer"
                            style={{ borderRadius: "5px" }}
                            onClick={() => {
                              setSelectedIccid(iccid);
                              fetchLineActivity(id);
                            }}
                            title="esim line activity"
                          />
                        </div>
                      </th>
                    </tr>
                  )
                )
              )}
            </tbody>
          )}
        </table>
      </div>
      <UsageModal
        show={showModal}
        handleClose={handleClose}
        eSimDetail={eSimDetail}
        isLoading={loading}
      />
      {/* Modal */}
      {showEmailModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Send Email</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleResendEmailClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="emailInput" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSendEmail}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {selectedEsimData && (
        <EsimDetailsModal
          show={showEsimDetailModal}
          esimData={selectedEsimData}
          onClose={handleCloseEsimModal}
          isLoading={eSimDetailLoader}
        />
      )}

      {showLineActivityModal && (
        <div
          className={`modal fade ${
            showLineActivityModal ? "show d-block" : ""
          }`}
          tabIndex="-1"
          style={{
            backgroundColor: showLineActivityModal
              ? "rgba(0, 0, 0, 0.5)"
              : "transparent",
          }}
        >
          <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Line Activity ({selectedIccid})</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={hnadleLineActivityModlaClose}
                ></button>
              </div>
              <EsimLineActivityModal
                tableData={tableData}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EsimTable;
