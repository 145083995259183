import axios from "axios";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Error500 from "../../components/500/Error500";
import { tr } from "date-fns/locale";

const AdminWhatsapp = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [networkIssue, setNetworkIssue] = useState(false);
  const [maxAllowedPerson, setMaxAllowedPerson] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    allow_update: 0,
    allow_error: 0,
  });
  const [selectedId, setSelectedId] = useState(null);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [activeToggle, setActiveToggle] = useState(false);
  const [failed, setFailed] = useState("");
  console.log(tableData, "table data check");

  // Toggle Modal
  const toggleModal = () => {
    setShowModal((prev) => !prev);
    setFormData({
      name: "",
      number: "",
      allow_update: 0,
      allow_error: 0,
    });
  };

  const toggleDeleteModal = (id) => {
    setSelectedId(id); // Set the selected user ID
    setShowDeleteModal((prev) => !prev); // Toggle modal visibility
  };
  const cancelDelete = () => {
    toggleDeleteModal();
    setSelectedId(null);
  };

  const confirmDelete = () => {
    updatePrivileges(selectedId, "block");
    toggleDeleteModal();
  };

  {
    /* ================= Api =================== */
  }
  const handleApiError = (err) => {
    setIsLoading(false);
    if (err.response) {
      const status = err.response.status;
      if (status === 400) {
        toast.error(err.response.data.message || "Bad Request");
      } else if (status === 401) {
        navigate("/");
      } else if (status === 500) {
        toast.error(err.response.data.message || "Internal Server Error");
      } else {
        toast.error("An unexpected error occurred.");
      }
    } else {
      setNetworkIssue(true);
      toast.error("Network error. Please check your connection.");
      setFailed("Network error. Please check your connection.");
    }
  };

  const fetchAdminWhatsapp = () => {
    setActiveToggle(true);
    setIsLoading(true);
    axios
      .get(`${app_url}/api/portalUser/getAdminWhatsappUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTableData(res?.data?.users);
        setMaxAllowedPerson(res?.data?.allowedUsers);
        setActiveToggle(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setActiveToggle(false);
        handleApiError(err);
      });
  };

  useEffect(() => {
    fetchAdminWhatsapp();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : e.target.value,
    });
  };

  const handleAdminWhatsappRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${app_url}/api/portalUser/registerAdminWhatsappUsers`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Admin added Successfully!");
        fetchAdminWhatsapp();
        toggleModal();
      })
      .catch((err) => {
        handleApiError(err);
        toggleModal();
      });
  };

  const updatePrivileges = (id, slug) => {
    axios
      .post(
        `${app_url}/api/portalUser/privilegesOnAdminWhatsappUsers`,
        {
          id,
          slug,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          fetchAdminWhatsapp(); // Refresh table data
        }
      })
      .catch((err) => {
        handleApiError(err);
      });
  };

  const newArr = new Array(12).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-2 ps-5">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
          <th scope="row" className="text-center py-2">
            {item}
          </th>
          <th scope="row" className="text-center py-2">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
          <th scope="row" className="py-2">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  if (networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <>
      <section className="wrapper">
        <div className="d-md-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0">Admin Whatsapp Notif (Allowed Person)</p>
          <div className="d-flex justify-content-between  gap-2 align-items-center">
            <p className="fs-6 fs-md-5 fw-semibold mb-0">
              Max Allowed: {maxAllowedPerson}
            </p>
            <button onClick={toggleModal} className="btn card-btn py-2">
              Add Admin
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table mt-4 table-tb">
            <thead>
              <tr className="rounded">
                <th className="tr-tt fw-bold ps-5" scope="col">
                  #
                </th>
                <th className="tr-tt fw-bold" scope="col">
                  Name
                </th>
                <th className="tr-tt fw-bold" scope="col">
                  Number
                </th>

                <th className="tr-tt fw-bold" scope="col">
                  Allow Update
                </th>
                <th className="tr-tt fw-bold" scope="col">
                  Allow Error
                </th>
                <th className="tr-tt fw-bold" scope="col">
                  isActive
                </th>
                <th className="tr-tt fw-bold" scope="col">
                  Created
                </th>
                <th className="tr-tt fw-bold" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            {isLoading ? (
              skeleton
            ) : (
              <tbody>
                {tableData?.length === 0 ? (
                  <tr>
                    <th className="py-4"></th>
                    <th className="py-4"></th>
                    <th className="py-4"></th>
                    <th className="py-4"></th>
                    <th className="py-4 align-middle">No Data Found</th>
                    <th className="py-4"></th>
                    <th className="py-4"></th>
                    <th className="py-4"></th>
                  </tr>
                ) : (
                  tableData?.map(
                    (
                      {
                        name,
                        number,
                        allow_update,
                        allow_error,
                        isActive,
                        created_at,
                        id,
                      },
                      index
                    ) => (
                      <tr key={index}>
                        <th className="py-2 ps-5 align-middle">{index + 1}</th>
                        <th className="py-2 align-middle">{name}</th>
                        <th className="py-2 align-middle">{number}</th>
                        <th className="py-2 align-middle">
                          <div className="form-check form-switch mt-0 me-2">
                            <input
                              style={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                              }}
                              className={`form-check-input active-unactive-pkg ${
                                allow_update === 0
                                  ? "unactive-pkg"
                                  : "active-pkg"
                              }`}
                              defaultChecked={allow_update === 1 ? true : false}
                              type="checkbox"
                              role="switch"
                              id={`allowUpdate-${index}`}
                              onChange={() =>
                                updatePrivileges(id, "allow_update")
                              }
                              disabled={activeToggle}
                            />
                          </div>
                        </th>
                        <th className="py-2 align-middle">
                          {" "}
                          <div className="form-check form-switch mt-0 me-2">
                            <input
                              style={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                              }}
                              className={`form-check-input active-unactive-pkg ${
                                allow_error === 0
                                  ? "unactive-pkg"
                                  : "active-pkg"
                              }`}
                              onChange={() =>
                                updatePrivileges(id, "allow_error")
                              }
                              defaultChecked={allow_error === 1 ? true : false}
                              type="checkbox"
                              role="switch"
                              id={`allowUpdate-${index}`}
                              disabled={activeToggle}
                            />
                          </div>
                        </th>
                        <th className="py-2 align-middle">
                          {" "}
                          <div className="form-check form-switch mt-0 me-2">
                            <input
                              style={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                              }}
                              className={`form-check-input active-unactive-pkg ${
                                isActive === 0 ? "unactive-pkg" : "active-pkg"
                              }`}
                              onChange={() => updatePrivileges(id, "isActive")}
                              defaultChecked={isActive === 1 ? true : false}
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              disabled={activeToggle}
                            />
                          </div>
                        </th>
                        <th className="py-2 align-middle">
                          {new Date(created_at)?.toLocaleString()}
                        </th>
                        <td style={{ color: "red" }}>
                          <i
                            className="bi bi-trash text-danger fs-5 d-flex"
                            onClick={() => toggleDeleteModal(id)}
                          ></i>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            )}
          </table>
        </div>
      </section>

      {/* Modal for Add Admin Whatsapp */}
      <div
        className={`modal fade ${showModal ? "show d-block" : ""}`}
        tabIndex="-1"
        style={{
          backgroundColor: showModal ? "rgba(0, 0, 0, 0.5)" : "transparent",
        }}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Admin Whatsapp
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={toggleModal}
              ></button>
            </div>
            <div className="modal-body">
              {/* Add Admin Form inside Modal */}
              <form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter full name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="number" className="form-label">
                    Phone Number
                  </label>
                  <PhoneInput
                    name="number"
                    id="phoneNumber"
                    country={"us"}
                    value={formData.number}
                    onChange={(value) =>
                      setFormData({ ...formData, number: value })
                    }
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={{
                      width: "100%",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between row m-0">
                  <div className="mb-3 form-check t col-6">
                    <input
                      type="checkbox"
                      className="form-check-input custom-checkbox"
                      id="allowUpdate"
                      name="allow_update"
                      onChange={handleChange}
                      checked={formData.allow_update === 1}
                    />
                    <label className="form-check-label" htmlFor="allowUpdate">
                      Allow Update
                    </label>
                  </div>

                  <div className="mb-3 form-check col-6">
                    <input
                      type="checkbox"
                      className="form-check-input custom-checkbox"
                      id="allowError"
                      name="allow_error"
                      checked={formData.allow_error === 1}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="allowError">
                      Allow Error
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAdminWhatsappRegister}
                disabled={isLoading}
              >
                Add
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm text-small ms-2"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {/* Modal for Add Admin Whatsapp */}
      <div
        className={`modal fade ${showDeleteModal ? "show" : ""}`}
        style={{ display: showDeleteModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <p>Are you sure you want to delete this admin whatsapp?</p>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default AdminWhatsapp;
