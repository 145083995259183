import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PortalUserTable from "../../components/tables/PortalUserTable";
import Pagination from "../../components/tables/Pagination";
import axios from "axios";
import toast from "react-hot-toast";
import _ from "lodash";
import { app_url } from "../../config";
import Error500 from "../../components/500/Error500";

const PortalUser = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [portalUserData, setPortalUserData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("all");
  const [networkIssue, setNetworkIssue] = useState(false);
  const [failed, setFailed] = useState("");
  const [dataOnPage, setDataOnPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = dataOnPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [search, setSearch] = useState("");

  console.log(tableData, "table data check");

  const handleApiError = (err) => {
    setTableDataLoading(false);
    if (err.response) {
      const status = err.response.status;
      if (status === 400) {
        toast.error(err.response.data.message || "Bad Request");
      } else if (status === 401) {
        navigate("/");
      } else if (status === 500) {
        toast.error(err.response.data.message || "Internal Server Error");
      } else {
        toast.error("An unexpected error occurred.");
      }
    } else {
      setNetworkIssue(true);
      toast.error("Network error. Please check your connection.");
      setFailed("Network error. Please check your connection.");
    }
  };

  // fetch Portal Users Orders
  const fetchPortalUsers = useCallback(
    _.debounce((search, selectedUserType) => {
      setTableDataLoading(true);
      axios
        .get(
          `${app_url}/api/portalUser/getAllAdminUsers?numPerPage=${dataOnPage}&page=${
            currentPage - 1
          }&search=${search}&userType=${selectedUserType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (itemsPerPage > tableData?.data?.pagination?.dataCount) {
            setCurrentPage(1);
          }
          setTableData(res);
          setTableDataLoading(false);
        })
        .catch((err) => {
          handleApiError(err);
        });
    }, 500),
    [dataOnPage, currentPage, token, navigate]
  );

  useEffect(() => {
    fetchPortalUsers(search, selectedUserType);
  }, [search, currentPage, token, selectedUserType]);

  // ================= Search Pegination ===================
  const searchData = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // ================= Handl Pagination Page ===================
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <section className="wrapper">
      <div className="d-flex flex-wrap gap-3 justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="heading mb-0"> Portal User</p>
          <div className="d-flex gap-2">
            <Link to={"/portalUser/userLogs"} className="btn card-btn py-2">
              User Logs
            </Link>

            <Link
              to={"/portalUser/addPortalUser"}
              className="btn card-btn py-2"
            >
              Add Portal User
            </Link>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center w-100">
          <form autoComplete="off">
            <input
              style={{ width: 270 }}
              className="form-control"
              type="text"
              placeholder="Search by Name"
              aria-label="Search"
              value={search}
              onChange={searchData}
              autoComplete="off"
            />
          </form>
          <div className="d-flex align-items-center gap-2 mt-md-0 mt-2">
            <p className="fs-5 mb-0 fw-semibold">Filter:</p>
            <select
              value={selectedUserType}
              onChange={(e) => {
                setSelectedUserType(e.target.value);
              }}
              className="border rounded px-2 py-1 "
              style={{ width: 200 }}
            >
              <option value="" disabled>
                Select Portal User
              </option>
              <option value="all">All</option>
              <option value="superadmin">Super Admin</option>
              <option value="admin">Admin</option>
              <option value="support">Support</option>
              <option value="marketing">Marketing</option>
              <option value="billing">Billing</option>
              <option value="sales">Sales</option>
            </select>
          </div>
        </div>
      </div>
      <PortalUserTable
        tableData={tableData}
        isLoading={tableDataLoading}
        handleApiError={handleApiError}
        fetchPortalUsers={fetchPortalUsers}
      />
      <Pagination
        dataOnPage={dataOnPage}
        currentPage={currentPage}
        totalPages={Math.ceil(
          tableData?.data?.pagiantion.dataCount / itemsPerPage
        )}
        tabledata={tableData}
        onPageChange={handlePageChange}
        indexOfFirstItem={indexOfFirstItem}
        itemsPerPage={itemsPerPage}
        indexOfLastItem={indexOfLastItem}
      />
    </section>
  );
};

export default PortalUser;
