import React, { useState } from "react";
import Loader from "../Loader";
import toast from "react-hot-toast";

const EsimDetailsModal = ({ show, onClose, esimData, isLoading }) => {
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [isHoveredICCID, setisHoveredICCID] = useState(false);
  const [isHoveredActivationCode, setisHoveredActivationCode] = useState(false);
  const [isHoveredMatchingId, setisHoveredMatchingId] = useState(false);
  const [isHoveredAppleInstallationURL, setisHoveredAppleInstallationURL] =
    useState(false);
  const [isHoveredAPN, setisHoveredAPN] = useState(false);
  const [copiedICCID, setCopiedICCID] = useState(false);
  const [copiedActivationCode, setCopiedActivationCode] = useState(false);
  const [copiedMatchingId, setCopiedMatchingId] = useState(false);
  const [copiedAppleInstallationURL, setCopiedAppleInstallationURL] =
    useState(false);
  const [copiedAPN, setCopiedAPN] = useState(false);

  const handleCopyICCID = () => {
    navigator.clipboard.writeText(esimData?.iccid).then(() => {
      setCopiedICCID(true);
      setTimeout(() => setCopiedICCID(false), 1000);
    });
  };

  const handleCopyActivationCode = () => {
    navigator.clipboard.writeText(esimData?.activationCode).then(() => {
      setCopiedActivationCode(true);
      setTimeout(() => setCopiedActivationCode(false), 1000);
    });
  };

  const handleCopyMatchingId = () => {
    navigator.clipboard.writeText(esimData?.matchingId).then(() => {
      setCopiedMatchingId(true);
      setTimeout(() => setCopiedMatchingId(false), 1000);
    });
  };

  const handleAppleInstallationURL = () => {
    navigator.clipboard
      .writeText(esimData?.direct_apple_installation_url)
      .then(() => {
        setCopiedAppleInstallationURL(true);
        setTimeout(() => setCopiedAppleInstallationURL(false), 1000);
      });
  };
  const handleAPN = () => {
    navigator.clipboard.writeText(esimData?.apnValue).then(() => {
      setCopiedAPN(true);
      setTimeout(() => setCopiedAPN(false), 1000);
    });
  };

  // Tooltip styling
  const tooltipStyle = {
    position: "absolute",
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#333",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    whiteSpace: "nowrap",
    zIndex: 1,
    opacity: 0.8,
  };

  const handleDownload = async () => {
    setDownloadLoader(true);
    try {
      const response = await fetch(esimData?.qrCodeUrl, {
        mode: "cors", // Enable this if the QR code URL is from a different domain
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `qrcode-${esimData?.iccid}-${new Date().getTime()}.png`;
      link.click();
      URL.revokeObjectURL(url);
      setDownloadLoader(false);
    } catch (error) {
      setDownloadLoader(false);
      toast.error("Error downloading the QR code");
      console.error("Error downloading the QR code:", error);
    }
  };
  console.log(isLoading, "esim detail loader check in modal");

  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      style={{ backgroundColor: show ? "rgba(0, 0, 0, 0.5)" : "transparent" }}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">eSIM Details</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <div
                style={{
                  height: "20vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "8em",
                }}
              >
                <Loader />
              </div>
            ) : (
              <>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th>ICCID</th>
                      <td
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseEnter={() => setisHoveredICCID(true)}
                        onMouseLeave={() => setisHoveredICCID(false)}
                        onClick={handleCopyICCID}
                      >
                        {esimData?.iccid}
                        {isHoveredICCID && (
                          <span style={tooltipStyle}>
                            {copiedICCID ? "Copied!" : "Click to copy"}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p style={{ marginBottom: "0px" }}> Activation Code</p>
                        <p style={{ marginBottom: "0px", fontSize: "12px" }}>
                          (Manual Installation)
                        </p>
                      </th>
                      <td
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          wordBreak: "break all",
                        }}
                        onMouseEnter={() => setisHoveredActivationCode(true)}
                        onMouseLeave={() => setisHoveredActivationCode(false)}
                        onClick={handleCopyActivationCode}
                      >
                        {esimData?.activationCode}
                        {isHoveredActivationCode && (
                          <span style={tooltipStyle}>
                            {copiedActivationCode ? "Copied!" : "Click to copy"}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Matching ID</th>
                      <td
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          wordBreak: "break all",
                        }}
                        onMouseEnter={() => setisHoveredMatchingId(true)}
                        onMouseLeave={() => setisHoveredMatchingId(false)}
                        onClick={handleCopyMatchingId}
                      >
                        {esimData?.matchingId}
                        {isHoveredMatchingId && (
                          <span style={tooltipStyle}>
                            {copiedMatchingId ? "Copied!" : "Click to copy"}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Apple Installation URL</th>
                      <td
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          wordBreak: "break-all",
                        }}
                        onMouseEnter={() =>
                          setisHoveredAppleInstallationURL(true)
                        }
                        onMouseLeave={() =>
                          setisHoveredAppleInstallationURL(false)
                        }
                        onClick={handleAppleInstallationURL}
                      >
                        {esimData?.direct_apple_installation_url}
                        {isHoveredAppleInstallationURL && (
                          <span style={tooltipStyle}>
                            {copiedAppleInstallationURL
                              ? "Copied!"
                              : "Click to copy"}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>APN</th>
                      <td
                        style={{
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onMouseEnter={() => setisHoveredAPN(true)}
                        onMouseLeave={() => setisHoveredAPN(false)}
                        onClick={handleAPN}
                      >
                        {esimData?.apnValue}
                        {isHoveredAPN && (
                          <span style={tooltipStyle}>
                            {copiedAPN ? "Copied!" : "Click to copy"}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>QR Code</th>
                      <td>
                        <button
                          className="btn btn-success"
                          onClick={handleDownload}
                          style={{
                            backgroundColor: "#F57C21",
                            color: "#fff",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Download
                          {downloadLoader ? (
                            <span
                              className="spinner-border spinner-border-sm text-light"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsimDetailsModal;
