import { addDays, setSeconds, setHours, setMinutes } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { formatDate } from "../../formatDate/formatDate";
import {
  FaBox,
  FaTags,
  FaPercent,
  FaUserTie,
  FaUsers,
  FaBell,
  FaBullhorn,
  FaUserShield,
  FaWhatsapp,
} from "react-icons/fa";
import _ from "lodash";
import axios from "axios";
import { app_url } from "../../config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Error500 from "../../components/500/Error500";
import Pagination from "../../components/tables/Pagination";

const UserLogs = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [networkIssue, setNetworkIssue] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [search, setSearch] = useState("");
  const datePickerRef = useRef(null);
  const [filterValue, setFilterValue] = useState(""); // State for selected value
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("PACKAGE"); // State for active tab
  const [loading, setLoading] = useState(false);
  const [dataOnPage, setdataOnPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = dataOnPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [failed, setFailed] = useState("");

  const tabs = [
    { id: "PACKAGE", label: "Package", icon: <FaBox /> },
    { id: "PROMO_CODE", label: "Promo Code", icon: <FaTags /> },
    { id: "DISCOUNT_CODE", label: "Discount Code", icon: <FaPercent /> },
    { id: "PARTNER", label: "Partner", icon: <FaUserTie /> },
    { id: "AFFILIATE_PARTNER", label: "Affiliate Partner", icon: <FaUsers /> },
    {
      id: "PUSH_NOTIFICATION_TEMPLATE",
      label: "Push Template",
      icon: <FaBell />,
    },
    {
      id: "PUSH_NOTIFICATION_CAMPAIGN",
      label: "Push Campaign",
      icon: <FaBullhorn />,
    },
    { id: "PORTAL_USER", label: "Portal User", icon: <FaUserShield /> },
    {
      id: "ADMIN_WHATSAPP_NOTIFICATION",
      label: "WhatsApp Notification",
      icon: <FaWhatsapp />,
    },
  ];

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  function getLastDayOfMonth(year, month) {
    const nextMonth = month + 1;
    const date = new Date(year, nextMonth, 0);
    return date.getDate();
  }

  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  // const getStartDateForInitialState = () => {
  //   const actualDate = addDays(new Date(), +1 - new Date().getDate());
  //   const adjustedStartDate = setSeconds(
  //     setMinutes(setHours(actualDate, 0), 0),
  //     0
  //   );
  //   return adjustedStartDate;
  // };

  const getStartDateForInitialState = () => {
    // Default start date to January 1, 2022
    return new Date("2022-01-01");
  };

  const [state, setState] = useState([
    {
      startDate: getStartDateForInitialState(),
      endDate: new Date(year, month, getLastDayOfMonth(year, month)),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setCurrentPage(1);
    const { startDate, endDate } = ranges.selection;
    const adjustedEndDate = setSeconds(
      setMinutes(setHours(endDate, 23), 59),
      59
    );
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(startDate, 0), 0),
      0
    );
    setState([
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ]);
  };

  // Date Picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [dateFrom, setdateFrom] = useState(
    formatDate(getStartDateForInitialState())
  );
  const [dateTo, setdateTo] = useState(formatDate(state[0].endDate));
  const [initialDateFrom, setInitialDateFrom] = useState(
    formatDate(state[0].startDate)
  );

  //update dateFrom and dateTo based on state changes
  useEffect(() => {
    const formattedStartDate = formatDate(state[0].startDate);
    const formattedEndDate = formatDate(state[0].endDate);

    setdateFrom(formattedStartDate);
    setdateTo(formattedEndDate);
  }, [state]);

  console.log(dataOnPage, "data on per page check");

  // fetch User Activivity
  const fetchActivityLogs = useCallback(
    _.debounce((search) => {
      setLoading(true);
      axios
        .get(
          `${app_url}/api/portalUser/get_all_action_logs?numPerPage=${dataOnPage}&page=${
            currentPage - 1
          }&from=${dateFrom}&to=${dateTo}&search=${search}&slug=${activeTab}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (itemsPerPage > tableData?.data?.pagiantion.dataCount) {
            setCurrentPage(1);
          }
          setLoading(false);
          setTableData(res);
          console.log(res, "res check");
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response) {
            if (err.response.status === 401) {
              navigate("/");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message);
            } else {
              toast.error("An unexpected error occurred.");
            }
          } else {
            setNetworkIssue(true);
            toast.error(
              "Network error. Please check your internet connection."
            );
            setFailed("Network error. Please check your internet connection.");
          }
        });
    }, 500),
    [dateFrom, dateTo, dataOnPage, currentPage, activeTab]
  );

  useEffect(() => {
    fetchActivityLogs(search);
  }, [search, currentPage, token, activeTab, fetchActivityLogs]);

  // ================= Search Pegination ===================
  const searchData = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setCurrentPage(1);

    // If the search has a value, set dateFrom to "2025-01-01"
    if (searchValue) {
      setdateFrom("2022-01-01");
    } else {
      setdateFrom(initialDateFrom);
    }
  };

  // ================= Handl Pagination Page ===================

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSearch("");
    setdataOnPage(25);
    // Reset startDate to "2022-01-01" and endDate to the last day of the current month
    const resetStartDate = new Date("2022-01-01");
    const resetEndDate = new Date(year, month, getLastDayOfMonth(year, month));
    setState([
      {
        startDate: resetStartDate,
        endDate: resetEndDate,
        key: "selection",
      },
    ]);

    // Update dateFrom and dateTo to reflect the new date range
    setdateFrom(formatDate(resetStartDate));
    setdateTo(formatDate(resetEndDate));
    setCurrentPage(1);
  };

  const newArr = new Array(12).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-3 ps-5 align-middle">
            {item}
          </th>
          <th scope="row" className="py-3 align-middle">
            {item}
          </th>
          <th scope="row" className="py-3 align-middle">
            {item}
          </th>
          <th scope="row" className="py-3 col-4 align-middle">
            {item}
          </th>
          <th scope="row" className="py-3 align-middle">
            {item}
          </th>
          <th scope="row" className="py-3 align-middle">
            {item}
          </th>
          <th scope="row" className="py-3 align-middle">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  if (networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <>
      <section className="wrapper">
        <div className="d-flex flex-wrap gap-3 justify-content-between align-items-baseline">
          <form className="d-flex flex-wrap gap-3 align-items-baseline">
            <div className="" style={{ width: 230 }}>
              <input
                className="form-control"
                type="search"
                placeholder="Search User"
                aria-label="Search"
                value={search}
                onChange={searchData}
              />
              <p className="mt-2 text-muted small">
                Search by: <strong>first name</strong>,{" "}
                <strong>last name</strong>, <strong>email</strong>, or{" "}
                <strong>details</strong>.
              </p>
            </div>
            <div
              className="dropdown d-flex flex-wrap gap-3 align-baseline align-items-center"
              ref={datePickerRef}
            >
              <button
                className="btn btn-white border dropdown-toggle"
                type="button"
                onClick={toggleDatePicker}
              >
                Select Date
              </button>
              <div className="d-flex align-items-center gap-1 dropdown">
                <button
                  type="button"
                  disabled
                  className="text-black btn btn-white border "
                >
                  {dateFrom}
                </button>
                -
                <button
                  type="button"
                  disabled
                  className="text-black btn btn-white border "
                >
                  {dateTo}
                </button>
              </div>

              {showDatePicker && (
                <ul
                  className="dropdown-menu px-2 py-0 show"
                  style={{ top: "37px", left: "-219px" }}
                >
                  <li className="my-2 dropdown-item">
                    <DateRangePicker
                      onChange={handleSelect}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction="horizontal"
                    />
                  </li>
                </ul>
              )}
            </div>
          </form>
          <div className="d-flex align-items-center">
            <span className="pe-2">Show:</span>
            <select
              value={dataOnPage}
              className="form-select"
              onChange={(e) => setdataOnPage(e.target.value)}
            >
              <option value="" disabled>
                Select Value
              </option>
              {[25, 50, 100, 250, 500].map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <p className="heading mt-4 mb-3">Activity Logs</p>
          <ul className="nav nav-tabs mb-3 border-0">
            {tabs.map((tab) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link d-flex align-items-center gap-2 px-4 py-2 ${
                    activeTab === tab.id ? "active-tab" : "inactive-tab"
                  }`}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.icon}
                  <span>{tab.label}</span>
                </button>
              </li>
            ))}
          </ul>

          <div className="tab-content">
            {tableData?.data?.result.length > 0 ? (
              <div
                className="table-responsive"
                style={{
                  overflow: "scroll",
                }}
              >
                <table className="table table-tb">
                  <thead>
                    <tr className="rounded">
                      <th className="tr-tt fw-bold ps-5">Action For</th>
                      <th className="tr-tt fw-bold ">Action Type</th>
                      <th className="tr-tt fw-bold ">Action ID</th>
                      <th className="tr-tt fw-bold col-4" scope="col">
                        Action Details
                      </th>
                      <th className="tr-tt fw-bold">Portal User Details</th>
                      <th className="tr-tt fw-bold">Role</th>
                      <th className="tr-tt fw-bold">IP Address</th>
                    </tr>
                  </thead>

                  <>
                    {loading ? (
                      skeleton
                    ) : (
                      <tbody>
                        {tableData?.data?.result.map((log, index) => (
                          <tr key={index}>
                            <td className="ps-5 align-middle">
                              {log.action_for}
                            </td>
                            <td className="align-middle">{log.action_type}</td>
                            <td className="align-middle">
                              {log.action_for_id}
                            </td>
                            <td
                              className="col-4"
                              scope="col"
                              title={log.action_details}
                            >
                              {log.action_details?.length > 150
                                ? `${log.action_details.slice(0, 150)}...`
                                : log.action_details}
                            </td>
                            <td className="align-middle">
                              <span style={{ wordBreak: "break-all" }}>
                                <b className="fw-semibold">ID: </b>
                                {log.portal_user_id}
                              </span>
                              <br />
                              <span>
                                <b className="fw-semibold">Name: </b>{" "}
                                {`${log.portal_user_first_name} ${log.portal_user_last_name}`}
                              </span>
                              <br />
                              <span style={{ wordBreak: "break-all" }}>
                                <b className="fw-semibold">Email: </b>
                                {log.portal_user_email}
                              </span>
                            </td>
                            <td className="align-middle">
                              {log.portal_user_type}
                            </td>
                            <td className="align-middle">
                              {log.ip_address}
                              <br />
                              {new Date(log.created_at).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </>
                </table>
              </div>
            ) : (
              <p>
                No activity logs available for {activeTab.replace(/_/g, " ")}.
              </p>
            )}
          </div>
        </div>

        <Pagination
          dataOnPage={dataOnPage}
          currentPage={currentPage}
          totalPages={Math.ceil(tableData?.data?.pagiantion.pages)}
          tabledata={tableData}
          onPageChange={handlePageChange}
          indexOfFirstItem={indexOfFirstItem}
          itemsPerPage={itemsPerPage}
          indexOfLastItem={indexOfLastItem}
        />
      </section>
    </>
  );
};

export default UserLogs;
